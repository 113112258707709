import { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import WcmsMessage from './WcmsMessage'
import logo from './img/Toray_Group_rogo.svg'

const useStyles = makeStyles(theme => ({
    root : {
        marginTop : 80,
        position : 'relative',

        '& .subtitle' : {
            fontSize : 20,
            fontWeight : 'bold',
        },
        '& footer' : {
            display : 'flex',
            flexDirection : 'column',
            alignItems : 'center',
            marginTop : 20,
            marginBottom : 20,

            '& img' : {
                height : 28 ,
                marginTop : 7,
                marginBottom : 7,
            },
        },
        '& .MuiButton-root' : {
            padding : '4px 12px',
            minWidth : 80,
        },
        '& input' : {
            padding : 4,
        },
        '& select' : {
            padding : 4,
        },
        '& textarea' : {
            padding : 4,
        },
    }
}))

const WcmsContent = ({subtitle, children, msg, topChildren}) => {
    const classes = useStyles()
    const copyright = useMemo(() => `Copyright © ${new Date().getFullYear()} TORAY INDUSTRIES, INC.`, [])

    return (
        <Container maxWidth="xl" className={classes.root}>
            {topChildren}
            <div className="subtitle">{subtitle}</div>
            <WcmsMessage msg={msg}/>
            {children}
            <footer>
                <small>{copyright}</small>
                <img className="group-logo" src={logo} alt="logo" />
            </footer>
        </Container>
    )
}


export default WcmsContent
