import { useState, useEffect, useCallback, useRef, memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Amplify, { API } from 'aws-amplify'
import MessageIcon from '@material-ui/icons/Message'
import Button from '@material-ui/core/Button'
import awsconfig from './aws-exports'
import { TextField, IconButton  } from '@material-ui/core'
import Spinner from './Spinner'
import MiniSpinner from './MiniSpinner'
import AddressSelector from './AddressSelector'
import ReplyIcon from '@material-ui/icons/Reply'
import ReplyAllIcon from '@material-ui/icons/ReplyAll'
import commonFunc from './commonFunc'

const border = 'solid 1px #ccc'
const useStyles = makeStyles(theme => ({
    root : {
        display : 'flex',
        position : 'fixed',
        top : 76,
        right : 0,
        height : '90%',
        zIndex : 100,

        '& .comment-tab' : {
            padding : 4,
            height : 35,
            color : '#fff',
            backgroundColor : '#3498db',
            cursor : 'pointer',

            '&.closed' : {
                backgroundColor : '#ccc',
            },
        },
        '& .comment-area' : {
            overflow: 'auto',
            width : 300,
            backgroundColor : '#fff',
            border : border,

            '& .post' : {
                padding : 6,
                borderBottom : border,
            },
            '& .edit-area' : {
                borderBottom : border,

                '& .MuiFormControl-root' : {
                    display : 'flex',
                    margin : 5,
    
                    '& .MuiInputBase-root' : {
                        padding : 5,
                        margin : 3,
                    },
                },
                '& .button-area' : {
                    display : 'flex',
                    justifyContent : 'space-around',
                    padding : 6,
                    '& .MuiButton-root' : {
                        minWidth : 100,
                    },
                },
                '& .autocomp-area' : {
                    width : '94.5% !important',
                },
            },
        },
        '& .comments' : {
            '& .comment' : {
                padding : 6,
                borderBottom : border,
                '& .header' : {
                    display : 'flex',
                    justifyContent : 'space-between',
                    '& .idName' : {
                        display : 'flex',
                        fontSize : '15px',
                        '& .name' : {
                            fontWeight : 600,
                            color : '#217dbb',
                            width : 130,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        },
                        '& .betweenSpace' : {
                            padding : '0px 3px',
                        },
                    }
                },
                '& .mentionName' : {
                    width : '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                },
                '& .date' : {
                    fontSize : '0.75rem',
                    paddingBottom : 4,
                    textAlign : 'right',
                },
                '& .text' : {
                    wordBreak : 'break-word',
                    whiteSpace : 'pre-wrap',
                }
            },
        },
        '& .load-button' : {
            display : 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width : '100%',
            '& .load-text' : {
                fontWeight : 600,
                color : '#217dbb',
                fontSize : '15px',
                lineHeight: '4',
            },
        },
        '& .spinnerDiv' : {
            display : 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        '& .reply-button-area' : {
            display : 'flex',
            justifyContent : 'end',
            '& .icon-text' : {
                fontSize : '12px',
            },
            '& .MuiIconButton-root' : {
                width : 95,
            },
        },
    },
}))

Amplify.configure(awsconfig)
const apiname = awsconfig.aws_cloud_logic_custom[0].name

const Comments = memo(({
    userInfo,             // ログインユーザ情報
    dict,                 // 多言語辞書
    setErr,               // エラーセッター
    appNo,                // フォームアプリID
    currentAppNo,         // 現在の転送先フォームアプリID
    record,               // 問合せ情報
    formInfo,             // フォーム情報
    allDepartments,       // 全部署の名前ID対応リスト
    setAllDepartments,    // 全部署の名前ID対応リストセッター
    getMailtemplates,     // メールテンプレートゲッター
    form,                 // 画面構築用フォーム情報
    visibleDepartmentIds  // 詳細画面を閲覧可能な部署ID配列
}) => {
    const [open, setOpen] = useState(false)
    const [input, setInput] = useState(false)
    const [inputComment, setInputComment] = useState('')
    const [dispComments, setDispComments] = useState([])
    const [isReacquire, setIsReacquire] = useState(true)
    const [spinnerOpen, setSpinnerOpen] = useState(false)
    const [target, setTarget] = useState([])
    const [validate, setValidate] = useState({})
    const [mailTemplate, setMailtemplate] = useState({})
    const [isOlder, setIsOlder] = useState(false)
    const [offset, setOffset] = useState(0)
    const [miniSpinnerOpen, setMiniSpinnerOpen] = useState(false)
    const classes = useStyles()
    const scrollRef = useRef(null)

    /**
     * コメント変換処理
     *  取得したコメントオブジェクトのテキストからメンション情報をobject形式で抜き出す＆テキストのメンション情報部分を空白に置換して返却する処理
     * c：コメントオブジェクト
     */
    const transDispComment = useCallback((c) => {
        const [, mentionObjJson, mainText] = c.text.match(/^\$({.+?})\$(.*)$/s) || []
        if (!mentionObjJson) return c
        else {
            try {
                const mentionObj = JSON.parse(mentionObjJson)
                return {...c, text : mainText, mentionInfo : mentionObj}
            } catch {
                // 対象文字列が予期せぬ形式だった場合、変換せずに表示する
                return c
            }
        }
    }, [])

    /**
     * コメント&メールテンプレート取得
     * 初回遷移時、コメント登録時に実行される
     */
    useEffect(() => {(async () => {
        if (!appNo || !record || !formInfo || !form.length || !userInfo) return
        // コメント登録後再取得は1度のみ実行する
        if (!isReacquire) return 
        try {
            setIsReacquire(false)
            const mailtemp = getMailtemplates((currentAppNo || appNo), formInfo, record, userInfo, ['コメント通知'], form) || {}
            setMailtemplate(mailtemp['コメント通知'][0])
            const {commentInfo : comment, resultCode, older} = await API.post(apiname, '/getComment', {body : {app : appNo, id : record.$id.value}})
            if (resultCode !== '00') {
                throw new Error(`コメント取得API NG (${resultCode})`)
            }
            if (comment.length) setOpen(true)
            const devDispComment = comment.map(c => transDispComment(c))
            setDispComments(devDispComment)
            setIsOlder(older)
            setOffset(comment.length)
        }
        catch (e) {
            setErr(e)
        }
    })()}, [appNo, record, isReacquire, setErr, getMailtemplates, formInfo, form, userInfo, dict, transDispComment, currentAppNo])

    /**
     * コメントを登録する
     * コメント登録後にコメントの再取得を行う
     */
    const insert = async c => {
        setSpinnerOpen(true)
        // サジェスト項目に連絡先が入力されていた場合、メール送信パラメタを追加する
        try {
            const devMail = target.length ? {
                Destination : {
                    ToAddresses : target.map(c => c.value),
                },
                Message : {
                    Subject : {
                        Data :mailTemplate.件名
                    },
                    Body : {
                        Text : {
                            Data : mailTemplate.本文.replace(/{commentText}/g, inputComment)
                        },
                    }
                },
                noReply : true
            } : null
            const mentionObj = {from : {label : userInfo.name, value : userInfo.email}, to : target}
            const editedComment = [`$${JSON.stringify(mentionObj)}$`, inputComment].join("\n")
            const params = {
                app : appNo,
                id : record.$id.value,
                userName : userInfo.name,
                comment : editedComment,
            }
            if (devMail) params.Mails = [devMail]
            const {resultCode} = await API.post(apiname, '/insertComment', {body : params})
            if (resultCode !== '00') {
                throw new Error(`コメント登録API NG (${resultCode})`)
            }
            setInputComment('')
            setIsReacquire(true)
            setSpinnerOpen(false)
        }
        catch (e) {
            setSpinnerOpen(false)
            setErr(true)
        }
    } 

    const handleClick = () => {
        if (inputComment) {
            insert()
        }
    }

    const handleInput = e => {
        setInputComment(e.target.value)
    }

    /**
     * コメント欄の最上部にスクロールされるfnc
     */
    const scrollToInputForm = useCallback(() => {
        scrollRef?.current?.scrollIntoView({
            block: 'end',
        })
    }, [ scrollRef ])
 
    /**
     * 返信、全員返信ボタン押下時ハンドラ
     */
    const replyBtnClick = useCallback((index, isAll) => {
        setInput(true)
        const mentionInfo = dispComments[index].mentionInfo
        if (!mentionInfo) setTarget([])
        else {
            // ログインユーザーは除外＆投稿者メンション先のメアド重複削除
            if (isAll) setTarget([...new Map([mentionInfo.from].concat(mentionInfo.to).filter(c => c.value && userInfo.email !== c.value).map(v => [v.value, v])).values()])
            // ログインユーザーは除外
            else setTarget([mentionInfo.from].filter(c => c.value && userInfo.email !== c.value))
        }
        scrollToInputForm()
    }, [dispComments, userInfo, scrollToInputForm])
 
    /**
     * さらに読み込みボタンハンドラ
     * offset指定して取得していないコメントを最大10件取得する
     */
    const loadBtnClick = useCallback(async () => {
        setIsOlder(false)
        setMiniSpinnerOpen(true)
        try {
            const {commentInfo : comment, resultCode, older} = await API.post(apiname, '/getComment', {body : {app : appNo, id : record.$id.value, offset : offset}})
            if (resultCode !== '00') {
                throw new Error(`コメント取得API NG (${resultCode})`)
            }
            // idが重複したコメントは上書きして更新する
            setDispComments(d => [...new Map([...d, ...comment.map(c => transDispComment(c))].map(v => [v.id, v])).values()])
            setIsOlder(older)
            setOffset(i => i + comment.length)
            setMiniSpinnerOpen(false)
        } catch (e) {
            setMiniSpinnerOpen(false)
            setErr(e)
        }
    }, [offset, transDispComment, setErr, appNo, record])

    return (
        <div className={classes.root}>
            <div
                className={'comment-tab ' + (!open && 'closed')}
                onClick={() => setOpen(!open)}
            >
                <MessageIcon
                    fontSize="large"
                />
            </div>
            {open && 
            <div className="comment-area">
                <div ref={scrollRef}/>
                {!input &&
                <div className="post">
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setTarget([])
                            setInput(true)
                        }}
                    >
                        {dict.labels.コメントする}
                    </Button>
                </div>
                }
                {input &&
                <div className="edit-area">
                    <AddressSelector
                        onChange={v => {
                            setValidate({})
                            setTarget(v)
                        }}
                        value={target}
                        departments={visibleDepartmentIds}
                        allDepartments={allDepartments}
                        setAllDepartments={setAllDepartments}
                        setErr={setErr}
                        multi={true}
                        validate={validate.target}
                    />
                    <TextField
                        multiline
                        minRows={4}
                        value={inputComment}
                        onChange={e => handleInput(e)}
                    />
                    <div className="button-area">
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setInput(false)
                                setInputComment('')
                                setTarget([])
                            }}
                        >
                            {dict.labels.キャンセル}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                handleClick()
                                setInput(false)
                            }}
                            disabled={inputComment === ''}
                        >
                            {dict.labels.書き込む}
                        </Button>
                    </div>
                </div>
                }
                <div className="comments">
                    {dispComments.map((c, i) => 
                    <div key={i} className="comment">
                        <div className="header">
                            <div className="idName">
                                <div className="id">{`${c.id}`}</div>
                                <div className="betweenSpace">{`:`}</div>
                                <div
                                    className="name"
                                    title={`${c.mentionInfo ? c.mentionInfo.from.label : ''}`}
                                >
                                    {`${c.mentionInfo ? c.mentionInfo.from.label : ''}`}
                                </div>
                            </div>
                            <div className="date">{commonFunc.dateFormat(c.createdAt)}</div>
                        </div>
                        {((c.mentionInfo || {}).to || []).map(m => (
                            <div
                                className="mentionName"
                                title={m.label}
                            >
                                { `@${m.label}`}
                            </div> 
                        ))}
                        <div className="text">{c.text}</div>
                        <div className="reply-button-area">
                            <IconButton
                                onClick={() => replyBtnClick(i, false)}
                            >
                                <ReplyIcon
                                    variant="outlined"
                                    size="small"
                                />
                                <div class='icon-text'>{dict.labels.返信}</div>
                            </IconButton>
                            <IconButton
                                onClick={() => replyBtnClick(i, true)}
                            >
                                <ReplyAllIcon
                                    variant="outlined"
                                    size="small"
                                />
                                <div class='icon-text'>{dict.labels.全員返信}</div>
                            </IconButton>
                        </div>
                    </div>
                    )}
                    {isOlder && 
                    <IconButton
                        className='load-button'
                        onClick={() => {
                            loadBtnClick()
                        }}
                    >
                        <div class='load-text'>{dict.labels.さらに読み込む}</div>
                    </IconButton>
                    }
                    <div className='spinnerDiv'>
                        <MiniSpinner spinnerOpen={miniSpinnerOpen}/>
                    </div>
                </div>
            </div>
            }
            <Spinner spinnerOpen={spinnerOpen}/>
        </div>
    )
})

export default Comments
