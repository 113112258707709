import {useState , useEffect, useCallback} from 'react'
import { API } from 'aws-amplify'
import { makeStyles } from '@material-ui/core/styles'
import awsconfig from './aws-exports'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import Autocomplete from '@material-ui/core/Autocomplete'
import selectorStyle from './selectorStyle'

const useStyles = makeStyles(selectorStyle)
const apiname = awsconfig.aws_cloud_logic_custom[0].name
const textRegex = /^[!#$%^&*()+\-=[\]{}?~|<>]|.*['].*/

/**
 * 指定部署のユーザーコンボボックスコンポーネント
 * メアドの直接入力は不可
 */
const AddressSelector = ({
    onChange,            // 値が変更されたときのハンドラ
    value,               // 上の階層に渡す入力値（ユーザー情報配列）
    allDepartments,      // 全部署の名前ID対応リスト
    setAllDepartments,   // 全部署の名前ID対応リストセッター
    departments,         // 検索対象の部署配列
    multi,               // 複数入力可否の真偽値
    userInfo,            // ログインユーザー情報、存在する場合、ログインユーザーを選択肢から外す
    setErr,              // エラーハンドリングファンクション
    validate,            // ヴァリデーション真偽値
    customStyles         // カスタムスタイルオブジェクト（サジェスト選択肢のStyleを上の階層から渡すことが可能）
}) => {
    const [inputValue, setInputValue] = useState('')
    const [options, setOptions] = useState([])
    const [apiDoing, setApiDoing] = useState(false)
    const [composing, setComposing] = useState(false)
    const classes = useStyles()
    const customClasses = customStyles ? makeStyles(customStyles)() : {}

    // 検索APIの実行処理
    const execApi = useCallback(async (v ,departmentIds, departmentNames) => {
        const reqBody = v ? {
            text : v,
            departmentNames : departmentNames,
            departmentIds : departmentIds
        } : {
            departmentNames : departmentNames,
            departmentIds : departmentIds
        }
        const ret = await API.post(apiname, '/getUserInfo', {body : reqBody})
        if (ret.resultCode !== '00') throw new Error(`ユーザー情報取得API実行エラー：${ret.resultCode}`)
        let userInfoList = ret.userInfo
        // ユーザーリストからログインユーザーを除外
        if (userInfo) userInfoList = userInfoList.filter(c => c.mail !== userInfo.email)
        setOptions(userInfoList.map(c => ({label : c.displayName, value : c.mail})))
        return ret
    }, [userInfo])

    // 該当部署の全ユーザー取得(入力値がないときの検索)
    useEffect(() => {(async () => {
        if (options.length || apiDoing || inputValue || !departments.length) return
        const departmentNames = allDepartments ? departments.filter(c => allDepartments[c]).map(c => allDepartments[c]) : []
        try {
            setApiDoing(true)
            const ret = await execApi(null , departments, departmentNames)
            if (Object.keys(ret.allDepartments).length > 0 && !allDepartments) {
                setAllDepartments(ret.allDepartments)
            }
            setApiDoing(false)
        }
        catch (e) {
            setApiDoing(false)
            setErr(e)
        }
    })()}, [options, apiDoing, inputValue, execApi, allDepartments, setAllDepartments, departments, userInfo, setErr])

    // 入力値変更ハンドラ
    const handleOnInputChange = async (e, v, r) => {
        setOptions([])
        setInputValue(v)
        if (composing) return
        if (v) await getUser(v)
    }

    // 日本語変換開始ハンドラ
    const handleCompositionStart = e => {
        setComposing(true)
    }
    // 日本語変換終了ハンドラ
    const handleCompositionEnd = async (v) => {
        setComposing(false)
        if (v) await getUser(v)
    }

    // 選択値表示時ハンドラ
    const handleRenderTags = (v, getTagProps) => {
        return v.map((v, index) => (
            <Chip
                {...getTagProps({index})}
                label={v.label}
            />
        ))
    }

    // ユーザー検索ハンドラ（入力した際の都度検索）
    const getUser = async v => {
        if (textRegex.test(v)) return
        try {
            const departmentNames = allDepartments ? departments.filter(c => allDepartments[c]).map(c => allDepartments[c]) : []
            const ret = await execApi(v , departments, departmentNames)
            if (Object.keys(ret.allDepartments).length > 0 && !allDepartments) {
                setAllDepartments(ret.allDepartments)
            }
        }
        catch (e) {
            setErr(e)
        }
    }

    return (
        <div className={classes.root}>
            <div className="autocomp-area">
                <Autocomplete
                    classes={{
                        paper : customClasses.paper || classes.paper,
                        option : customClasses.option || classes.option,
                    }}
                    // 追加入力許可の判定 + 入力値有無のヴァリデーション
                    className={((!multi && value.length > 0) ? 'unableInput' : '') + (validate ? 'error' : '')}
                    multiple
                    autoSelect
                    blurOnSelect={!multi}
                    options={options}
                    value={value}
                    inputValue={inputValue} 
                    getOptionLabel={o => `${o.label}（${o.value}）`}
                    getOptionSelected={(o, v) => o.value === v.value}
                    onChange={(e, v) =>onChange(v)}
                    onInputChange={handleOnInputChange}
                    renderInput={p =>
                        <TextField 
                            {...p}
                            onCompositionStart={handleCompositionStart}
                            onCompositionEnd={e => handleCompositionEnd(inputValue)}
                        />
                    }
                    renderTags={handleRenderTags}
                    size='small'
                />
            </div>
        </div>
    )
}

export default AddressSelector
