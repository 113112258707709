import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import WcmsModal from './WcmsModal'
import Amplify, { API } from 'aws-amplify'
import awsconfig from './aws-exports'
import Spinner from './Spinner'

Amplify.configure(awsconfig)
const apiname = awsconfig.aws_cloud_logic_custom[0].name

const useStyles = makeStyles(theme => ({
    root : {
        '& .message' : {
            whiteSpace : 'pre-wrap',
            fontSize : '14px',
            padding : '0px 20px',
        },
    },
}))

const CompleteModal = ({showMsg, open, closeModal, appNo, record, userInfo, dict, setErr}) => {
    const [spinnerOpen, setSpinnerOpen] = useState(false)
    const classes = useStyles()
    const history = useHistory()

    // 問合せ登録更新API実行
    const excecApi = async() => {
        try {
            setSpinnerOpen(true)
            const devSendParam = {
                app : appNo,
                id : record.$id.value,
                revision : record.$revision.value,
                問合せステータス : '回答済み'
            }
            const ret = await API.post(apiname, '/registerContact', {body : devSendParam})
            if (ret.resultCode === '03') {
                setSpinnerOpen(false)
                showMsg('error', dict.messages.排他エラー)
            } else if (ret.resultCode !== '00') {
                throw new Error(`問合せ登録更新API NG (${ret.resultCode})`)
            } else {
                setSpinnerOpen(false)
                showMsg('success', dict.messages.問合せ対応完了)
                history.push(`/`)
            }
        } catch (e) {
            setSpinnerOpen(false)
            setErr(e)
        }
    }

    const clearModal = () => {
        closeModal()
    }

    return (
        <WcmsModal
            dict={dict}
            open={open}
            closeModal={clearModal}
            title={dict.labels.確認}
            execLabel={dict.labels.はい}
            execFunc={excecApi}
        >
            <div className={classes.root}>
                    <div className="message">
                        {dict.messages.対応完了確認テキスト}
                    </div>
            </div>
            <Spinner spinnerOpen={spinnerOpen}/>
        </WcmsModal>
    )
}

export default CompleteModal
