import { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import { makeStyles } from '@material-ui/core/styles'
import awsconfig from './aws-exports'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import Autocomplete from '@material-ui/core/Autocomplete'
import selectorStyle from './selectorStyle'

const useStyles = makeStyles(selectorStyle)
const apiname = awsconfig.aws_cloud_logic_custom[0].name
const mailRegex = /[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}/
const textRegex = /^[!#$%^&*()+\-=[\]{}?~|<>]|.*['].*/

/**
 * 全ユーザーのコンボボックスコンポーネント
 * 入力でメアドを打ち込むことも可能
 * メールアドレスの形式チェックを行う
 */
const FreeAddressSelector = ({
    value,               // 上の階層に渡す入力値（ユーザー情報配列）
    onChange,            // 値が変更されたときのハンドラ
    defaultOptions,      // 初期サジェスト表示リスト（ユーザー情報）
    dict,                // ラベル表示用辞書
    setErr,              // エラーハンドリングファンクション
    userInfo,            // ログインユーザー情報
    validate,            // ヴァリデーション真偽値
    multi,               // 複数入力可否の真偽値
    isRemoveUser,        // ログインユーザ除外の真偽値
    ignoreMailRegex,     // メールアドレス形式チェック実施有無の真偽値 （trueの場合実施なし）
    customStyles         // カスタムスタイルオブジェクト（サジェスト選択肢のStyleを上の階層から渡すことが可能）
}) => {
    const [options, setOptions] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [composing, setComposing] = useState(false)
    const classes = useStyles()
    const customClasses = customStyles ? makeStyles(customStyles)() : {}

    // 初期サジェストを表示
    useEffect(() => {
        if (!inputValue) setOptions(defaultOptions)
    }, [defaultOptions, inputValue, options])

    // 入力値変更ハンドラ
    const handleOnInputChange = async (e, v, r) => {
        setOptions([])
        setInputValue(v)
        if (composing) return
        if (v) await getUser(v)
    }

    // 値更新時ハンドラ
    const handleOnChange = (e, v, r) => {
        // メアド直接入力時は値の整形及び形式チェックを実施
        v = v.map(v => typeof v === 'string' ? {label : v, value : v, err : !ignoreMailRegex ? !mailRegex.test(v) : false} : v)
        onChange(v)
    }

    // 選択値表示時ハンドラ
    const handleRenderTags = (v, getTagProps) => {
        return v.map((v, index) => (
            <Chip
                {...getTagProps({index})}
                label={v.label}
                className={v.err ? 'error' : ''}
            />
        ))
    }

    // 日本語変換開始ハンドラ
    const handleCompositionStart = e => {
        setComposing(true)
    }
    // 日本語変換終了ハンドラ
    const handleCompositionEnd = async (v) => {
        setComposing(false)
        if (v) await getUser(v)
    }

    // AADから候補ユーザー一覧取得
    const getUser = async (v) => {
        // API実行エラー防止のため先頭文字列チェック
        if (textRegex.test(v)) return
        try {
            const ret = await API.post(apiname, `/getUserInfo`, {body : {
                text : v,
            }})
            if(ret.resultCode !=='00') {
                throw new Error(`API実行エラー：${ret.resultCode}`)
            }
            let userInfoList = ret.userInfo
            // ユーザーリストからログインユーザーを除外するか判定（転送先アドレスから除外）
            if (userInfo && isRemoveUser) userInfoList = userInfoList.filter(c => c.mail !== userInfo.email)
            setOptions([...defaultOptions, ...userInfoList.map(c => ({label : c.displayName, value : c.mail}))])
        }
        catch (e) {
            setErr(e)
        }
    }

    return (
        <div className={classes.root}>
            <div className={`autocomp-area${multi ? "" : " single"}`}>
                <Autocomplete
                    classes={{
                        paper : customClasses.paper || classes.paper,
                        option : customClasses.option || classes.option,
                    }}
                    // 追加入力許可の判定 + 入力値がメールアドレス形式であるかのヴァリデーション
                    className={((!multi && value.length > 0) ? "unableInput" : "") + (!ignoreMailRegex && (value.some(v => v.err) || validate) ? " error" : "")}
                    multiple
                    autoSelect
                    blurOnSelect={!multi}
                    freeSolo
                    options={options}
                    value={value}
                    inputValue={inputValue}
                    getOptionLabel={o => o.default ? `${o.label}` : `${o.label} (${o.value})`}
                    onChange={handleOnChange}
                    onInputChange={handleOnInputChange}
                    renderInput={p =>
                        <TextField 
                            {...p}
                            onCompositionStart={handleCompositionStart}
                            onCompositionEnd={e => handleCompositionEnd(inputValue)}
                        />
                    }
                    renderTags={handleRenderTags}
                    size='small'
                />
            </div>
            {!ignoreMailRegex && value.some(v => v.err) &&
                <div className="message-area">{dict.messages.アドレス形式エラー}</div>}
        </div>
    )
}

export default FreeAddressSelector
