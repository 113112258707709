const errBg = '#ffebec'
const selectorStyle = {
    root : {
        fontSize : '14px',
        '& .autocomp-area' : {
            width : 500,
        },
        '& .autocomp-area.single' : {
            display : 'inline-box',
            width : 200,
        },
        '& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiOutlinedInput-sizeSmall' : {
            padding : 2,
        },
        '& .MuiAutocomplete-root.error' : {
            backgroundColor : errBg,
            marginRight : 4,
        },
        '& .MuiChip-root' : {
            height : 28,
            borderRadius : 14,
            marginTop : 2,
            marginBottom : 2,
            marginRight : 6,
            overflow : 'hidden',
        },
        '& .message-area' : {
            width : 230,
            color : 'red',
            paddingTop : 4,
        },
        '& .MuiChip-root.error' : {
            color : 'red',
        },
        '& .MuiAutocomplete-root.unableInput input' : {
            display : 'none'
        },
        '& .MuiAutocomplete-popupIndicator' : {
            display : 'none'
        }
    },
    paper : {
        fontSize : 14,
    },
    option : {
        paddingTop : 2,
        paddingBottom : 2,
        whiteSpace : 'pre-wrap',
        wordBreak : 'break-word',
    },
}

export default selectorStyle