import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'

/*
 * E-Mailアドレスのカスタム項目作成コンポーネント
 */
const EmailAddress = ({dict, field, customItem, changeCustomForm, changeValidateMsg}) => {

    const handleChange = (event, field, index) => {
        // エラーメッセージのリセット
        changeValidateMsg({[field.code] : ''})

        const value = customItem
        value[index] = event.target.value

        changeCustomForm({[field.code] : value})
    }

    return (
        <Box>
            <TextField
                size="small"
                value={customItem[0]}
                onChange={e => handleChange(e, field, 0)}
            />
            <TextField
                className="withLabel"
                InputLabelProps={{shrink : true}}
                label={dict.labels.確認用}
                size="small"
                value={customItem[1]}
                onChange={e => handleChange(e, field, 1)}
            />
        </Box>
    )
}

export default EmailAddress
