import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import FormSelector from './FormSelector'
import FreeAddressSelector from './FreeAddressSelector'

const useStyles = makeStyles(theme => ({
    root : {
        display : 'flex',
        alignItems : 'flex-start',

        '& .label' : {
            minWidth : 150,
            maxWidth : 150,
            padding : 4,
            marginRight : 4,
            background : '#eee',
        },
        '& .value' : {
            marginRight : 30,
            display : 'flex',
            alignItems : 'center',
        },
        '& .MuiInput-root' : {
            width : 200,
        },
        '& .MuiTextField-root' : {
            width : 200,
        },
        '& .MuiFormControlLabel-root' : {
            marginLeft : 0,
        },
        '& input[type="date"]' : {
            border : 'solid 1px rgba(0, 0, 0, 0.23)',
            borderRadius : 4,
            boxSizing : 'border-box',
            height : 29,
            outline : 0,
            width : 135,

            '&:focus' : {
                border : 'solid 2px #3f51b5',
            },
        },
        '& .MuiChip-root' : {
            height : '21px !important'
        },
    },
}))

const Condition = ({
    type,               // 入力欄のタイプ
    label,              // 項目のラベル名
    form,               // フォームオブジェクト
    name,               // フォームの項目名
    options,            // 選択肢
    selectValue,        // Autocompleteの値
    changeCondition,    // 値変更関数
    userInfo,           // ログインユーザ情報
    setErr,             // Error設定関数
    dict,               // ラベル表示用辞書
    ignoreMailRegex     // メールアドレス形式チェック実施有無の真偽値 （trueの場合実施なし）
}) => {
    const classes = useStyles()
    if (!form) return null

    const value = form ? form[name] : null
    const props = {
        value : value,
        onChange : e => changeCondition({[name] : e.target.value})
    }

    // 問合わせステータスのハンドラ
    const handleCheck =
        (e, v) => changeCondition({[name] : e.target.checked ? value.concat(v) : value.filter(l => l !== v)})

    return (
        <div className={classes.root}>
            <div className="label">{label}</div>
            <div className="value">
                {options ? (
                    type === 'checkbox' ? (
                        options.map((o, i) =>
                            <FormControlLabel
                                key={i}
                                label={o.label}
                                control={
                                    <Checkbox
                                        checked={value.includes(o.value)}
                                        onChange={e => handleCheck(e, o.value)}
                                        color="primary"
                                    />
                                }
                            />
                        )
                    )
                    : type === 'combo' ? (
                        <FormSelector
                            value={selectValue}
                            options={options}
                            changeValue={changeCondition}
                        />
                    )
                    : (
                        <Select
                            displayEmpty
                            {...props}
                        >
                            <MenuItem value=''>----</MenuItem>
                            {options.map(o =>
                            <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>
                            )}
                        </Select>
                    )
                )
                : type === 'mail' ? (
                    <FreeAddressSelector
                        value={selectValue}
                        departments={[]}
                        onChange={changeCondition}
                        allDepartments={undefined}
                        setAllDepartments={(v) => {}}
                        setErr={setErr}
                        dict={dict}
                        defaultOptions={[]}
                        userInfo={userInfo}
                        multi={false}
                        ignoreMailRegex={ignoreMailRegex}
                        customStyles={{
                            paper : {
                                width : 500,
                                fontSize : 14,
                            }
                        }}
                    />
                )
                : type === 'dates' ? (<>
                    <input type="date" value={form[name[0]]} onChange={e => changeCondition({[name[0]] : e.target.value})} />
                    <span>～</span>
                    <input type="date" value={form[name[1]]} onChange={e => changeCondition({[name[1]] : e.target.value})} />
                </>)
                : (
                    <TextField
                        size="small"
                        {...props}
                    />
                )}
            </div>
        </div>
    )
}

export default Condition
