import React from 'react'
import Err from './Err'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error : undefined }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error : error}
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo)
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return <Err err={this.state.error }/>
    }

    return this.props.children
  }
}

export default ErrorBoundary