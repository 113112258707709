import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Amplify, { API, Storage } from 'aws-amplify'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AnswerModal from './AnswerModal'
import valid from './validate'
import awsconfig from './aws-exports'
import Spinner from './Spinner'

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop: 20,

        '& .MuiButtonBase-root' : {
            marginRight : 10
        },
    }
}))

Amplify.configure(awsconfig)
const apiname = awsconfig.aws_cloud_logic_custom[0].name

const TelInquiryRegister = ({isAdmin, isAssigner, isReplier, isTel, showMsg, appNo, record, setRevision, dict, setErr, formInfo, userInfo, allDepartments, setAllDepartments,
    mailContent, telFields, telForm, changeTelForm, telCustomForm, telValidateMsg, changeTelValidateMsg, cateFields, cateForm, changeCateForm, cateCustomForm, cateValidateMsg,
    changeCateValidateMsg, mailValidateMsg, changeMailValidateMsg, inquiryFiles, historyFiles}) => {
    const classes = useStyles()
    const history = useHistory()

    const [operation, setOperation] = useState('')
    const [spinnerOpen, setSpinnerOpen] = useState(false)

    const closeModal = () => setOperation('')

    /**
     * 登録処理
     */
    const handleChange = async (isTmp) => {
        setSpinnerOpen(true)
        // 問合せ情報に添付ファイルが設定されている場合は、ファイルの情報をフォームに詰め替える
        if (telForm && inquiryFiles) {
            const s3keys = Object.keys(inquiryFiles).reduce((o, c) => ({...o, [c] : inquiryFiles[c].reduce((o, r) => ([...o, r.s3key]), [])}), {})
            Object.keys(inquiryFiles).forEach(c => {
                telForm[c] = s3keys[c]
            })
        }

        // 問合せ情報と社内カテゴリ情報のそれぞれのカスタム項目をフォームに詰め替える
        const devTelForm = refillForm(telFields, telForm, telCustomForm, isTel)
        const devCateForm = refillForm(cateFields, cateForm, cateCustomForm, isTel)
        changeTelForm(devTelForm)
        changeCateForm(devCateForm)

        // kintone登録時にエラーにならないためのチェックを行うため、
        // 一時保存、登録時ともに同じバリデーションを実施する
        if (validate(isTmp)) {
            // 一時保存の場合は、登録処理を行う
            if (isTmp) {
                let params = {
                    app : appNo,
                    一時保存フラグ : isTmp,
                    社内カテゴリ情報 : devCateForm,
                    問合せステータス : '未対応'
                }

                const answerParam = record ? record.回答履歴テーブル.value.map(c => ({行番号 : c.id})) : [{}]
                
                answerParam[answerParam.length - 1] = {
                    回答方法 : mailContent.回答方法,
                    件名 : mailContent.件名,
                    回答内容 : mailContent.本文,
                    添付ファイル : (historyFiles.slice(-1)[0] || []).map(c => (c.s3key))
                }
                params.回答 = answerParam

                if (record) {
                    // 一時保存されていたレコードを更新する場合
                    // 更新用のパラメータを追加する
                    params = {...params, ...{
                        id : record.$id.value,
                        revision : record.$revision.value,
                    }}
                    delete params.問合せステータス
                }

                if (isTel) {
                    params = {...params, ...{
                        担当者名 : userInfo.name,
                        担当者メールアドレス : userInfo.email,
                        問合せ情報 : devTelForm,
                    }}
                }
                try {
                    if (inquiryFiles && Object.keys(inquiryFiles).length > 0) {
                        const inquiryFile = Object.keys(inquiryFiles).reduce((o, c) => 
                            [...o, ...inquiryFiles[c].reduce((o, r) => [...o, {s3key : r.s3key, file : r.file}], [])]
                        , [])
                        await Promise.all(inquiryFile.map(f => Storage.put(f.s3key, f.file)))
                    }
                    if (historyFiles && historyFiles.length > 0) {
                        const historyFile = historyFiles.slice(-1)[0].reduce((o, r) => 
                            [...o, {s3key : r.s3key, file : r.file}], []
                        )
                        await Promise.all(historyFile.map(f => Storage.put(f.s3key, f.file)))
                    }
                    const result = await API.post(apiname, '/registerContact', {body : params})
                    if (result.resultCode === '03') {
                        showMsg('error', dict.messages.排他エラー)
                        window.scrollTo(0, 0)
                        return
                    }
                    if (result.resultCode !== '00') throw new Error(`問合せ登録更新API NG (${result.resultCode})`)
                    if (record) setRevision(result.records.revision)
                    showMsg('info', dict.messages.一時保存完了)
                    window.scrollTo(0, 0)
                    history.push(`/detail/${appNo}?r=${result.records.id}`)
                    setSpinnerOpen(false)
                }
                catch (e) {
                    setSpinnerOpen(false)
                    setErr(e)
                }
            } else {
                // 登録時は送信モーダルを開く
                setSpinnerOpen(false)
                setOperation('電話応対記録')
            }
        }
        else {
            setSpinnerOpen(false)
            showMsg('error', dict.messages.入力エラー)
            window.scrollTo(0, 0)
        }
    }

    const validate = (isTmp) => {
        // 問合せ情報のバリデーション
        Object.values(telFields).forEach(f => {
            const errMsg = (f.code === "emailAddress" && telForm[f.code] ==='-') ? null : valid(f, telForm[f.code], dict.messages)
            if (errMsg) {
                telValidateMsg[f.code] = errMsg
            }
        })
        changeTelValidateMsg(telValidateMsg)

        // 一時保存ではない場合、回答履歴に値が設定されていない場合はエラー
        if (!isTmp) {
            if (!mailContent.本文 || mailContent.本文 === '') mailValidateMsg.本文 = true
            if (!mailContent.件名 || mailContent.件名 === '') mailValidateMsg.件名 = true
            changeMailValidateMsg({...mailValidateMsg})
        } 
        if (isTmp) {
            mailValidateMsg = {本文 : '', 件名 : ''}
            changeMailValidateMsg({...mailValidateMsg})
        }

        Object.values(cateFields).forEach(f => {
            const errMsg = valid(f, cateForm[f.code], dict.messages)
            if (errMsg) {
                cateValidateMsg[f.code] = errMsg
            }
        })
        changeCateValidateMsg(cateValidateMsg)

        return Object.values(telValidateMsg).every(r => !r) && Object.values(cateValidateMsg).every(r => !r) && Object.values(mailValidateMsg).every(r => !r)
    }

    return (
        <div className={classes.root}>
            {isTel &&
            <Button
                variant="contained"
                onClick={() => handleChange(false)}
            >
                {dict.labels.登録}
            </Button>
            }
            {
            // 一時保存ボタンの表示非表示制御
            //　電話応対記録時 → 表示
            // 「未対応」→　割振り担当者のみ表示（管理者も）
            // 「受付済」→　回答担当者のみ表示（管理者も）
            (
                isTel
                ||
                (record && record.問合せステータス.value === '未対応' && (isAdmin || isAssigner))
                ||
                ((record && (record.問合せステータス.value === '受付済み' || record.問合せステータス.value === '回答済み')) &&
                (isAdmin || isAssigner || isReplier))
            )
            &&
            <Button
                variant="contained"
                onClick={() => handleChange(true)}
            >   
                {dict.labels.一時保存}
            </Button>
            }
            <AnswerModal
                showMsg={showMsg}
                operation={operation}
                closeModal={closeModal}
                appNo={appNo}
                record={record}
                formInfo={formInfo}
                userInfo={userInfo}
                allDepartments={allDepartments}
                setAllDepartments={setAllDepartments}
                dict={dict}
                setErr={setErr}
                mailContent={mailContent}
                telForm={telForm}
                cateForm={cateForm}
                isTel={isTel}
                inquiryFiles={inquiryFiles}
                historyFiles={historyFiles}
                mailtemplate={[]}
            />
            <Spinner spinnerOpen={spinnerOpen}/>
        </div>
    )
}


/**
 * カスタム項目で設定した内容をformに詰め替える
 */
const refillForm = (fields, form, customForm, isTel) => {
    Object.values(fields).forEach(f => {
        if (f.customType === 'client') {
            form[f.code] = customForm[f.code].some(r => r) && customForm[f.code].join(' ')
        } else if (f.customType === 'email') {
            form[f.code] = customForm[f.code][0]
        } else if (f.customType === 'stAddress') {
            form[f.code] = (customForm[f.code] || []).join('')
        } else if (f.customType === 'otherChoice') {
            form[f.code] = customForm[f.code][0]
            form[f.code + '_other'] = customForm[f.code][1]
        } else if (f.customType) {
            form[f.code] = customForm[f.code]
        //電話応対記録時のみメールアドレスを自動であてはめる
        } else if (isTel && f.code === "emailAddress") {
            form[f.code] = form[f.code] || '-'
        }
    })
    return form
}

export default TelInquiryRegister
