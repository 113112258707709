import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import MenuItem from '@material-ui/core/MenuItem'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'

const useStyles = makeStyles(theme => ({
    bar : {
        paddingTop : 4,
        '& .MuiIconButton-root' : {
            marginLeft : -12,
            marginRight : 16,
            padding : 12,
            borderRadius : '50%',
        },
        '& .system-logo' : {
            height : 60,
        },
        '& .system-name' : {
            marginLeft : 100,
            paddingBottom : 8,
            fontSize : 30,
            fontWeight : 'bold',
            color : '#555',
            textShadow : '2px 2px 4px #bbb',
        },
    },
    menu : {
        '& .MuiDrawer-paper' : {
            padding : 4,
        },
        '& .MuiMenuItem-root' : {
            justifyContent : 'flex-start',
            width: 200,
            paddingLeft: 6,
        }
    },
}))

const ApplicationBar = ({logo, sysName, menus}) => {
    const [menu, setMenu] = useState(false)
    const history = useHistory()
    const classes = useStyles()

    const toggleMenu = () => setMenu(!menu)
    const handleMenu = p => {
        toggleMenu()
        history.push(p)
    }

    return (<>
        <Drawer
            open={menu}
            className={classes.menu}
            onClose={toggleMenu}
        >
            {menus.map((m, i) => (
                <MenuItem key={i} onClick={() => handleMenu(m.p)}>{m.n}</MenuItem>
            ))}
        </Drawer>
        <AppBar className={classes.bar}>
            <Toolbar>
                <IconButton onClick={toggleMenu}>
                    <MenuIcon />
                </IconButton>
                <div>
                    <img className="system-logo" src={logo} alt="logo" />
                </div>
                <div className="system-name">{sysName}</div>
            </Toolbar>
        </AppBar>
    </>)
}

export default ApplicationBar

