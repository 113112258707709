import React, { useState, useEffect  } from 'react'
import { API } from 'aws-amplify'
import CancelIcon from '@material-ui/icons/Cancel';
import awsconfig from './aws-exports'
import { makeStyles } from '@material-ui/core/styles'
import rejectExtension from './attachedFileRejectExtension.js'

const apiname = awsconfig.aws_cloud_logic_custom[0].name
const LIMIT_FILESIZE = 4*1024*1024

const useStyles = makeStyles(() => ({
    root: {
        '& .file-drop' : {
            boxSizing : 'border-box',
            padding: '10px',
            width: '100%',
            background: '#eee',
        },
        '& .file-drop.enter' :  {
            background: '#ddd',
        },
        '& .file-drop input' :  {
            display: 'none'
        },
        '& .file-drop a' :  {
            color: '#000',
        },
        '& .file-drop a[href]' :  {
            color: '#3498bd',
        },
        '& .file-drop .selected-files .file' :  {
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            background: '#f5f5f5',
        },
        '& .file-drop .selected-files .file:first-child' :  {
            marginTop: '4px',
        },
        '& .file-drop .selected-files .file:not(:last-child)' :  {
            marginBottom: '4px'
        },
        '& .file-drop .selected-files .file .remove' :  {
            fontSize: 18,
            margin: '4px',
            cursor: 'pinter',
            outline: 'none',
        },
        '& .file-drop .selected-files .file .name' :  {
            whiteSpace: 'nowrap',
            position: 'relative',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
        },
        '& .file-drop .selected-files .file .size' :  {
            whiteSpace: 'nowrap',
            position: 'relative',
        },
        '& .err' :  {
            whiteSpace: 'pre-wrap',
            color : 'red !important',
        },
    }
}))

/**
 * 添付ファイルコンポーネント
 * dict            辞書
 * fileInfo        レコード取得APIで取得したレコードの、添付ファイル項目オブジェクト ダウンロード対象
 * handleFileInfo  fileInfoの変更関数
 * allowUploads    アップロード可否真偽値
 * apiBrock        ダウンロードAPIを実行ブロッカー真偽値
 * setApiBrock     ダウンロードAPI実行ブロッカーのセッター
 */
const AttachedFile = ({dict, fileInfo, handleFileInfo, allowUploads, apiBrock, setApiBrock}) => {
    const [drop, setDrop] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const classes = useStyles()
    // 初期ダウンロード
    useEffect(() => {(async () => {
        if (apiBrock) return
        setErrMsg('')
        if ((fileInfo || []).length === 0) {
            setApiBrock(true)
            return
        }
        setApiBrock(true)
        const rets = await Promise.all(fileInfo.map(async v => {
            const ret = await API.post(apiname, '/downloadFile', {body : {file : v.fileKey}})
            if (ret.resultCode !== '00') throw new Error(`ファイルダウンロードAPI NG (${ret.resultCode})`)
            const file = base64toBlob(ret.data, v.contentType)
            return {file : file, url : URL.createObjectURL(file)}
        }))
        handleFileInfo(fileInfo.map((b, i) => ({
            name : b.name,
            size : b.size,
            file : rets[i].file,
            s3key  : b.name + '_' + Date.now(),
            url : rets[i].url,
        })))
    })()}, [fileInfo, allowUploads, apiBrock, setApiBrock, handleFileInfo])

    const handleRef = e => {
        e.preventDefault()
        e.target.previousSibling.click()
    }
    const handleAddFile = async e => {
        if (!allowUploads) return

        validate(e.currentTarget.files, dict)
        e.currentTarget.value = ''
    }
    const handleDelFile = i => {
        fileInfo.splice(i, 1)
        handleFileInfo([...fileInfo])
        setErrMsg('')
    }
    const handleDrop = e => {
        if (!allowUploads) return
        e.preventDefault()

        validate(e.dataTransfer.files, dict)
        setDrop(false)
    }
    const handleDragOver = e => {
        if (!allowUploads) return
        e.preventDefault()
        setDrop(true)
    }
    const handleDragLeave = e => {
        if (!allowUploads) return
        setDrop(false)
    }

    const validate = (files, dict) => {
        setErrMsg('')
        let err = false
        let totalFileSize = fileInfo.reduce((a, c) => a += parseInt(c.size), 0)
        const f = [...files].map(f => {
            totalFileSize += f.size
            if (!err) {
                err = validFile(f.name, dict)
                if (!err) {
                    return {
                        name : f.name,
                        size : f.size,
                        file : f,
                        s3key  : f.name + '_' +  Date.now()
                    }
                } else {
                    setErrMsg(err)
                }
            }
            return null
        })
        if (!err && totalFileSize >= LIMIT_FILESIZE) {
            err = dict.messages.ファイル容量超過エラー
            setErrMsg(err)
        }
        if (err) return
        handleFileInfo([...fileInfo, ...f])
    }

    return (
        <div className={classes.root}>
            <div className={'file-drop' + (drop ? ' enter' : '')} onDrop={handleDrop} onDragOver={handleDragOver} onDragLeave={handleDragLeave}>
                {allowUploads &&
                    <div>
                        <input type="file" multiple={true} className="input-file" onChange={handleAddFile}/>
                        <a href="/#" onClick={handleRef}>{dict.labels.参照}</a>
                    </div>
                }
                <div className="selected-files">
                    {fileInfo.map((v, i) => 
                    <div className="file" key={i}>
                        {allowUploads &&
                            <CancelIcon className="remove" onClick={() => handleDelFile(i)}></CancelIcon>
                        }
                        <div className="name">
                            <a href={(fileInfo[i] || {}).url} title={v.name} download={v.name}>{v.name}</a>
                        </div>
                        <div className="size">{fsize(v.size)}</div>
                    </div>
                    )}
                </div>
            </div>
            <div className="err">{errMsg}</div>
        </div>
    )
}

const base64toBlob = (b, t) => {
    const bin = atob(b)
    const buff = new Uint8Array(bin.length)
    for (let i=0; i < bin.length; i++) {
        buff[i] = bin.charCodeAt(i)
    }
    return new Blob([buff.buffer], {type : t})
}

const fsize = b => {
    const u = ['KB', 'MB', 'GB']
    let ret = `${b} B`
    for (let s=b/1024, i=0; s > 1; s /= 1024) {
        ret = `${Math.round(s)} ${u[i++]}`
    }
    return ret
}

/**
 * ファイルのバリデーション
 * ・拒否リストに載っている拡張子のファイルは添付できないようにする
 * 
 * エラーがある場合は、エラーメッセージを返却
 * エラーがなければ、falseを返却
 */
const validFile = (filename, dict) => {
    const ext = filename.split('.').slice(-1)[0]
    if (rejectExtension[ext]) {
        return dict.messages.対象外ファイルエラー.replace('{extension}', ext)
    }
    return false
}

export default AttachedFile
