import { useState , useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Autocomplete from '@material-ui/core/Autocomplete'
import FreeAddressSelector from './FreeAddressSelector'
import modalStyle from './modalStyle'
import SendModal from './SendModal'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(modalStyle)

//ソート比較関数
const sortNumber = (a, b) => {
    if (a.index === b.index) return 0
    if (a.index === '') return 1
    if (b.index === '') return -1
    return (Number(a.index) > Number(b.index)) ? 1 : -1
}

 /**
  * 転送モーダルコンポーネント
  */
const TransferModal = ({
    showMsg,         // メッセージ表示関数
    operation,       // 操作
    closeModal,      // モーダルクローズ関数
    appNo,           // フォームアプリID
    record,          // 問合せ情報
    formInfo,        // フォーム情報
    userInfo,        // ログインユーザ情報
    dict,            // 多言語辞書
    setErr,          // エラーセッター
    mailtemplate,    // メールテンプレート
    currentAppNo,    // 現在の転送先フォームアプリID
}) => {
    const [selectedValue, setSelectedValue] = useState('システム利用者')
    const [outUserTarget, setOutUserTarget] = useState([])
    const [formTarget, setFormTarget] = useState({label : '', value : null})
    const [validate, setValidate] = useState({})
    const [companyOptions, setCompanyOptions] = useState([])
    const [formTargetOptions, setFormTargetOptions] = useState([])
    const [company, setCompany] = useState({label : '', value : null})  
    const [mailContents, setMailContents] = useState([])
    const [sendParam, setSendParam] = useState(undefined)
    const classes = useStyles()

    // メールコンテンツの作成（転送先フォームの入力値に依存）
    useEffect(() => {
        if(!mailtemplate) return
        if (selectedValue === 'システム利用者') {
            let inAddress = {To : [], Cc : [], Bcc : []}
            if((formTarget || {}).value) {
                const assiners = formTarget.value.assigners
                const reps  = assiners.filter(c => c.rep)
                if(reps.length > 0) {
                    const To = reps.filter(c => c.sendCategory === 'To').map(c => c.assigner)
                    const Cc = reps.filter(c => c.sendCategory === 'Cc').map(c => c.assigner)
                    inAddress = {
                        To : To.length > 0 ? To : [Cc.shift()],
                        Cc : Cc,
                        Bcc : []
                    }
                } else {
                    const To = assiners.filter(c => c.sendCategory === 'To').map(c => c.assigner)
                    const Cc = assiners.filter(c => c.sendCategory === 'Cc').map(c => c.assigner)
                    inAddress = {
                        To : To.length > 0 ? To : [Cc.shift()],
                        Cc : Cc,
                        Bcc : []
                    }
                }
            }
            setMailContents([{
                title : dict.labels.転送先への通知メール,
                宛先 : inAddress,
                件名 : ((mailtemplate['転送通知(システム利用者)'] || [])[0] || {}).件名,
                本文 : ((mailtemplate['転送通知(システム利用者)'] || [])[0] || {}).本文,
                noReply : true,
            }])
        } else if (selectedValue === 'システム利用者外') {
            setMailContents(u => [{
                title : dict.labels.転送先への通知メール,
                // メアド形式エラーはToへ追加しない
                宛先 : {To : outUserTarget.filter(v => !v.err).map(c => c.value), Cc : [], Bcc : []},
                件名 : ((mailtemplate['転送通知(システム利用者以外)'] || [])[0] || {}).件名,
                本文 : (u[0] || {}).本文 || ((mailtemplate['転送通知(システム利用者以外)'] || [])[0] || {}).本文,
                replyTo : userInfo.email,
                noReply : true,
            }])
        }
    }, [outUserTarget, formTarget, selectedValue, dict, mailtemplate, userInfo])

    // 選択可能なフォーム（会社名の入力値に依存）の候補リストを生成する
    useEffect(() => {
        if (!formInfo || !record) return
        const formOptions = Object.keys(formInfo).map(c =>
                ({...formInfo[c], appId : c})
            )
            // 転送先除外、およびブラウザ言語のフォーム名の所持有無フィルタリング
            .filter(c => !c.excludeTransfer && c.formNames.find(e => e.lang === dict.lang))
            // 会社名によるフィルタリング
            .filter(c => !(company || {}).value || c.companyId === company.value)
            // 現在のフォームを除外
            .filter(c => c.appId !== (currentAppNo || appNo))
            // フォーム選択肢として整形
            .map(c =>
                ({
                    label : `${c.code} : ${c.formNames.find(e => e.lang === dict.lang).formName}`,
                    value : {appId : c.appId, assigners : c.assigners},
                    index : c.formSortNum
                })
            )
            .sort((a, b) => sortNumber(a, b))
        setFormTargetOptions(formOptions)
        setFormTarget({label : '', value : null})
    }, [company, formInfo, appNo, dict, record, currentAppNo])

    // 選択可能な会社名の候補リストを生成する
    useEffect(() => {
        const devCompanyOptions = []
        const duplicateOptions = []
        for (const appId in formInfo) {
            const targetName = formInfo[appId].companyNames.find(c => c.lang === dict.lang)
            if (targetName && !duplicateOptions.find(c => c === formInfo[appId].companyId)) {
                devCompanyOptions.push({label : targetName.companyName, value : formInfo[appId].companyId, index : formInfo[appId].companySortNum})
            }
            duplicateOptions.push(formInfo[appId].companyId)
        }
        devCompanyOptions.sort((a, b) => sortNumber(a, b))
        setCompanyOptions(devCompanyOptions)
    }, [formInfo, dict])

    // 登録更新パラメータの作成
    useEffect(() => {
        if (!record || !formTarget || 
            (selectedValue === 'システム利用者' && !formTarget.value) ||
            (selectedValue === 'システム利用者外' && outUserTarget.length === 0)) return
        const params = {
            app : appNo,
            id : record.$id.value,
            revision : record.$revision.value,
            担当者メールアドレス : '',
            担当者名 : '',
            サブ担当者 : ''
        }
        if (selectedValue === 'システム利用者')
            setSendParam({...params,
                問合せステータス : '未対応',
                転送先フォーム : formTarget.value.appId,
                転送元部署 : record.担当部署ID.value,
                操作履歴 : record.操作履歴テーブル.value.map(c => ({行番号 : c.id})).concat({
                    操作区分 : '転送',
                    操作者 : userInfo.name,
                    操作者メールアドレス : userInfo.email,
                    補足 : formTarget.label
                }),
            })
        if (selectedValue === 'システム利用者外')    
            setSendParam({...params,
                問合せステータス : '転送済み',
                転送元部署 : record.担当部署ID.value,
                操作履歴 : record.操作履歴テーブル.value.map(c => ({行番号 : c.id})).concat({
                    操作区分 : '転送',
                    操作者 : userInfo.name,
                    操作者メールアドレス : userInfo.email,
                    補足 : outUserTarget.map(c => c.value).join(',')
                }),
            })
    }, [outUserTarget, formTarget, selectedValue, record, userInfo, appNo])
    
    // キャンセルボタン押下時処理
    const cancelModal = () => {
        clearInput()
        closeModal()
        setSelectedValue('システム利用者')
    }

    // 入力値のクリア
    const clearInput = () => {
        setOutUserTarget([])
        setFormTarget({label : '', value : null})
        setValidate({})
        setCompany({label : '', value : null})
        setMailContents([])
    }

    // ヴァリデーション処理
    const validateFunc = () => {
        if (selectedValue === 'システム利用者外') {
            if (outUserTarget.length === 0 || outUserTarget.some(v => v.err)) {
                setValidate({outUserTarget : true})
                return true
            }
        } else if (selectedValue === 'システム利用者') {
            if (!(company || {}).value) {
                setValidate({company : true})
                return true
            }
            if (!(formTarget || {}).value) {
                setValidate({formTarget : true})
                return true
            }
        }
    }
    
    return (
        <SendModal
            showMsg={showMsg}
            open={operation === "転送"}
            closeModal={cancelModal}
            title={dict.labels.転送}
            validateFunc={validateFunc}
            dict={dict}
            setErr={setErr}
            mailContents={mailContents}
            sendParam={sendParam}
            setMailContents={setMailContents}
            record={record}
            successMessage={dict.messages.メール転送完了}
            contentEditable={selectedValue === 'システム利用者外'}
        >
            <div className={classes.root}>
                <div className='rows'>
                    <div className='row'>
                        <div className='text'>{dict.labels.転送タイプ}</div>
                            <RadioGroup
                                className='radio'
                                value={selectedValue}
                                onChange={e => {
                                    clearInput()
                                    setSelectedValue(e.target.value)
                                }}
                            >
                                <div>
                                    <FormControlLabel
                                        value='システム利用者'
                                        control={<Radio color="primary" />}
                                        label={<Typography>{dict.labels.システム利用者}</Typography>}
                                    />
                                    <FormControlLabel
                                        value='システム利用者外'
                                        control={<Radio color="primary" />}
                                        label={<Typography>{dict.labels.システム利用者外}</Typography>}
                                    />
                                </div>
                            </RadioGroup> 
                    </div>
                {selectedValue === 'システム利用者外' &&
                    <div className='row'>
                        <div className='text'>{dict.labels.転送先}</div>
                        <div className='input'>
                            <FreeAddressSelector
                                value={outUserTarget}
                                onChange={v => {
                                    setValidate({})
                                    setOutUserTarget(v)
                                }}
                                defaultOptions={((formInfo[currentAppNo] || {}).oftenUsers || [])}
                                dict={dict}
                                setErr={setErr}
                                userInfo={userInfo}
                                validate={validate.outUserTarget}
                                multi={true}
                                isRemoveUser={true}
                                ignoreMailRegex={false}
                            />
                        {validate.outUserTarget && <div className='errMessage'>{dict.messages.未入力エラー}</div>}
                        </div>
                    </div>
                }
                {selectedValue === 'システム利用者' &&
                    <div>
                        <div className='row'>
                            <div className='text'>{dict.labels.会社名}</div>
                            <div className="autocomplete">
                                <Autocomplete
                                    classes={{
                                        paper : classes.paper,
                                        option : classes.option,
                                    }}
                                    className={validate.company ? 'err' : ''}
                                    size='small'
                                    autoSelect
                                    blurOnSelect
                                    options={companyOptions}
                                    value={company}
                                    onChange={(e, v) => {
                                        setValidate({})
                                        setCompany(v)
                                    }}
                                    getOptionLabel={o => `${o.label}`}
                                    getOptionSelected={(o, v) => true}
                                    renderInput={p => <TextField {...p}/>}
                                />
                            {validate.company && <div className='errMessage'>{dict.messages.未入力エラー}</div>}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='text'>{dict.labels.転送先フォーム}</div>
                            <div className="autocomplete">
                                <Autocomplete
                                    classes={{
                                        paper : classes.paper,
                                        option : classes.option,
                                    }}
                                    className={validate.formTarget ? 'err' : ''}
                                    size='small'
                                    autoSelect
                                    blurOnSelect
                                    options={formTargetOptions}
                                    value={formTarget}
                                    onChange={(e, v) => {
                                        setValidate({})
                                        setFormTarget(v)
                                    }}
                                    disabled={!(company || {}).value}
                                    getOptionLabel={o => `${o.label}`}
                                    getOptionSelected={(o, v) => true}
                                    renderInput={p => <TextField {...p}/>}
                                />
                            {validate.formTarget && <div className='errMessage'>{dict.messages.未入力エラー}</div>}
                            </div>
                        </div>
                    </div>
                }
                </div>
            </div>
        </SendModal>
    )
}

export default TransferModal
