import { useState, useEffect, useCallback} from 'react'
import { inquiryStyle } from './inputStyle'
import InputItem from './InputItem'
import WcmsGroup from './WcmsGroup'
import customUse from './custom/customUse'


const TelInquiry = ({showMsg, appNo, record, dict, setErr, appInfo, fields, handleFields, form, changeForm, customForm, changeCustomForm,
    inquiryFiles, handleInquiryFiles, validateMsg, changeValidateMsg, levelItems, handleLevelItems}) => {

    const [levelCodes, setLevelCodes] = useState([])
    const [postCode, setPostCode] = useState({})
    const [address, setAddress] = useState({})
    const [selected, setSelected] = useState({})
    const changeSelected = useCallback(
        s => setSelected(c => ({...c, ...s}))
        ,[]
    )
    const changePostCode = useCallback(
        p => setPostCode(c => ({...c, ...p}))
        ,[]
    )
    const changeAddress = useCallback(
        a => setAddress(c => ({...c, ...a}))
        ,[]
    )

    /**
     * 問合せフォームの情報をもとに、画面構築用に配列作成
     */
    useEffect(() => {(async () => {
        if (!appInfo) return
        try {
            setAddress({})
            const f = createFields(appInfo)
            handleFields(f)
            const form = Object.keys(f).reduce((o, c) => {
                // formの初期値にはkintoneのデフォルト値を入れておく（文字列 || 配列）
                // ファイルにはデフォルト値を入れないので明示的に空配列を返却しておく
                if ('FILE' === f[c].type) {
                    return {...o, [c] : []}
                } else {
                    return {...o, [c] : ((record || {})[c] || {}).value == null ? f[c].defaultValue : ((record || {})[c] || {}).value}
                }
            }, {})
            changeForm(form)

            // カスタムフィールド項目用のform作成
            const customForm = Object.keys(f).reduce((o, c) => {
                const  devFieldValue = ((record || {})[c] || {}).value
                if (['stAddress'].includes(f[c].customType)) {
                    return {...o, [c] : [devFieldValue == null ? '' : devFieldValue, '']}
                }
                if (f[c].customType === 'otherChoice') {
                    return {...o, [c] : devFieldValue == null ? [f[c].defaultValue, ''] : [record[c].value, record[c + '_other'].value]}
                }
                if (f[c].customType) {
                    return {...o, [c] : devFieldValue == null ? f[c].defaultValue : devFieldValue}
                }
                return {...o}
            }, {})
            changeCustomForm(customForm)

            // 階層項目取得用のフィールド一覧の設定
            const levelCodes = Object.values(f).filter(r => r.customType === 'levelItems')
                .map(r => r.code.replace(/_level[1-3]$/, ''))
            setLevelCodes(Array.from(new Set(levelCodes)))
        }
        catch (e) {
            setErr(e)
        }
    })()}, [appInfo, record, setErr, handleFields, changeForm, changeCustomForm])

    return (
        <div>
            <WcmsGroup groupTitle={dict.labels.問合せ情報}>
                <div>
                    {Object.keys(fields).map(f =>
                    <InputItem
                        dict={dict}
                        style={inquiryStyle}
                        customUse={customUse}
                        key={f}
                        field={fields[f]}
                        form={form[f]}
                        changeField={changeForm}
                        customItem={customForm[f]}
                        changeCustomForm={changeCustomForm}
                        validateMsg={validateMsg[f]}
                        changeValidateMsg={changeValidateMsg}
                        levelCodes={levelCodes}
                        postCode={postCode}
                        changePostCode={changePostCode}
                        address={address}
                        changeAddress={changeAddress}
                        selected={selected}
                        changeSelected={changeSelected}
                        levelItems={levelItems}
                        handleLevelItems={handleLevelItems}
                        inquiryFiles={inquiryFiles}
                        handleInquiryFiles={handleInquiryFiles}
                        changeCustomValMsg={() => {}}
                        customValMsg={[]}
                        record={record}
                        isTel={true}
                    />
                    )}
                </div>
            </WcmsGroup>
        </div>
    )
}

/**
 * 入力フォームを表示するためのフィールド設定をkintoneのアプリ設定情報をもとに整形する
 * カスタムする必要のある項目はcustomTypeに該当のカスタム項目名を追加する
 * 
 * カスタム項目名
 *   client
 *   email
 *   levelItems
 *   otherChoice
 *   pcAddress
 *   stAddress
 */
 const createFields = (appsInfo) => {
    const layout = appsInfo.layout.find(r => r.code === "問合せ情報").layout
    return layout.reduce((l, r) => {
        const code = r.fields[0].code

        const f = {}
        if (layout.some(r => r.fields[0].code === code + '_other')) {
            f.customType = 'otherChoice'
        } else if (/_pc_address$/.test(code)) {
            const p = code.replace(/_pc_address$/, '')
            if (layout.some(r => r.fields[0].code === p + '_st_address')) {
                f.customType = 'pcAddress'
            }
        } else if (/_st_address$/.test(code)) {
            const p = code.replace(/_st_address$/, '')
            if (layout.some(r => r.fields[0].code === p + '_pc_address')) {
                f.customType = 'stAddress'
            }
        } else if (/_level[1-3]$/.test(code)) {
            f.customType = 'levelItems'
        }

        f.label = appsInfo.properties[code].label.replace(/【.+】/, '')

        // 複数フィールドが関連する項目の子要素として表示するため表示しなくなるフィールドかどうか
        if (/_other$/.test(code) && layout.some(r => code.replace(/_other$/, '') ===  r.fields[0].code)) {
            f.customNoDisp = true
        }
        return {...l, [code] : {...appsInfo.properties[code], ...f}}
    }, {})
}

export default TelInquiry
