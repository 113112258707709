import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import WcmsModal from './WcmsModal'
import Amplify, { API } from 'aws-amplify'
import awsconfig from './aws-exports'
import Spinner from './Spinner'

Amplify.configure(awsconfig)
const apiname = awsconfig.aws_cloud_logic_custom[0].name

const errBg = '#ffebec'
const useStyles = makeStyles(theme => ({
    root : {
        '& .reason' : {
            width : 600,
        },
        '& .errMessage' : {
            paddingTop : 4,
            color : 'red',
        },
        '& .MuiTextField-root.err' : {
            backgroundColor : errBg,
        },
    },
}))

const InvalidateModal = ({showMsg, open, closeModal, appNo, record, userInfo, dict, setErr}) => {
    const [reason, setReason] = useState('')
    const [errMessage, setErrMessage] = useState('')
    const [spinnerOpen, setSpinnerOpen] = useState(false)
    const classes = useStyles()
    const history = useHistory()

    // 問合せ登録更新API実行
    const excecApi = async () => {
        setSpinnerOpen(true)
        if(!reason) {
            setErrMessage(dict.messages.無効化理由未入力)
            setSpinnerOpen(false)
            return
        }
        try {
            const params = {
                app : appNo,
                id : record.$id.value,
                revision : record.$revision.value,
                問合せステータス : '無効化',
                無効化理由 : reason,
                操作履歴 : record.操作履歴テーブル.value.map(c => ({行番号 : c.id})).concat({
                    操作区分 : '無効化',
                    操作者 : userInfo.name,
                    操作者メールアドレス : userInfo.email,
                    補足 : ''
                })
            }
            const ret = await API.post(apiname, '/registerContact', {body : params})
            if (ret.resultCode === '03') {
                setSpinnerOpen(false)
                showMsg('error', dict.messages.排他エラー)
                return 
            }
            if (ret.resultCode !== '00') throw new Error(`問合せ登録更新API NG (${ret.resultCode})`)
            closeModal()
            setSpinnerOpen(false)
            history.push('/')
            showMsg('success', dict.messages.無効化完了)
        } catch (e) {
            setSpinnerOpen(false)
            setErr(e)
        }
    }

    const clearModal = () => {
        setErrMessage('')
        closeModal()
    }

    return (
        <WcmsModal
            dict={dict}
            open={open}
            closeModal={clearModal}
            title={dict.labels.無効化理由の入力}
            execLabel={dict.labels.無効化}
            execFunc={excecApi}
        >
            <div className={classes.root}>
                <TextField
                    className={'reason' + (errMessage ? ' err' : '')}
                    onChange={e => {
                        setErrMessage('')
                        setReason(e.target.value)
                    }}
                    multiline
                    minRows={8}
                />
                {errMessage &&
                    <div className='errMessage'>
                        {errMessage}
                    </div>}
            </div>
            <Spinner spinnerOpen={spinnerOpen}/>
        </WcmsModal>
    )
}

export default InvalidateModal
