import { useState , useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import AddressSelector from './AddressSelector'
import modalStyle from './modalStyle'
import SendModal from './SendModal'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(modalStyle)

/**
 * 回答者設定モーダルコンポーネント
 * 
 */
const AssignReplierModal = ({
    showMsg,           // メッセージ表示関数
    operation,         // 操作
    closeModal,        // モーダルクローズ関数
    appNo,             // フォームアプリID
    record,            // 問合せ情報
    formInfo,          // フォーム情報
    userInfo,          // ログインユーザ情報
    allDepartments,    // 全部署の名前ID対応リスト
    setAllDepartments, // 全部署の名前ID対応リストセッター
    dict,              // 多言語辞書
    setErr,            // エラーセッター
    mailtemplate       // メールテンプレート
}) => {
    const [selectedValue, setSelectedValue] = useState('送信する')
    const [isAssigned, setIsAssigned] = useState(false)
    const [target, setTarget] = useState([])
    const [subTarget, setSubTarget] = useState([])
    const [validate, setValidate] = useState({})
    const [mailContents, setMailContents] = useState([])
    const [sendParam, setSendParam] = useState(undefined)
    const classes = useStyles()

    // メールテンプレートからメール内容情報を作成
    useEffect(() => {
        if(!record || !mailtemplate) return
        const receptMailContent = {
            title :dict.labels.お客様への受付メール,
            宛先 : {To : [record.emailAddress.value], Cc : [], Bcc : []},
            件名 : ((mailtemplate.受付自動返信 || [])[0] || {}).件名,
            本文 : ((mailtemplate.受付自動返信 || [])[0] || {}).本文,
            noReply : false
        }
        const answerMailContent = {
            title : dict.labels.回答担当者への通知メール,
            宛先 : {To : target.map(c => c.value), Cc : subTarget.map(c => c.value), Bcc : []},
            件名 : ((mailtemplate.回答者通知 || [])[0] || {}).件名,
            本文 : ((mailtemplate.回答者通知 || [])[0] || {}).本文,
            noReply : true
        }
        if (selectedValue === '送信する') {
            setMailContents([receptMailContent, answerMailContent])
        } else if (selectedValue === '送信しない') {
            setMailContents([answerMailContent])
        }
    }, [target, subTarget, selectedValue, record, dict, mailtemplate])

    // 登録更新パラメータの作成
    useEffect(() => {
        if (!record || !mailContents || !target.length > 0) return
        setSendParam({
            app : appNo,
            id : record.$id.value,
            revision : record.$revision.value,
            問合せステータス : '受付済み',
            担当者メールアドレス : target[0].value,
            担当者名 : target[0].label,
            サブ担当者 : subTarget.length ? JSON.stringify(subTarget) : '',
            操作履歴 : record.操作履歴テーブル.value.map(c => ({行番号 : c.id})).concat({
                操作区分 : '担当者設定',
                操作者 : userInfo.name,
                操作者メールアドレス : userInfo.email,
                補足 : target[0].label
            }),
        })
    }, [target, subTarget, mailContents, record, userInfo, appNo])

    // 初回担当者設定か否か判定
    useEffect(() => {
        if (!record) return
        if (((record.操作履歴テーブル || {}).value || []).some(c => ((c.value || {}).操作区分 || {}).value === "担当者設定")) {
            setSelectedValue('送信しない')
            setIsAssigned(true)
        }
    }, [record])

    // キャンセルボタン押下時処理
    const cancelModal = useCallback(() => {
        setSelectedValue(isAssigned ? '送信しない' : '送信する')
        setTarget([])
        setSubTarget([])
        setValidate({})
        closeModal()
    }, [isAssigned, closeModal])

    // ヴァリデーション処理
    const validateFunc = () => {
        if(!target.length > 0) {
            setValidate({target : true})
            return true
        }
    }
    
    return (
        <SendModal
            showMsg={showMsg}
            open={operation === "回答者設定"}
            closeModal={cancelModal}
            title={dict.labels.回答担当者の設定}
            validateFunc={validateFunc}
            dict={dict}
            setErr={setErr}
            mailContents={mailContents}
            sendParam={sendParam}
            record={record}
            successMessage={dict.messages.回答者設定完了}
        >
            <div className={classes.root}>
                <div className='rows'>
                    <div className='row'>
                        <div className='text'>{dict.labels.質問受付メール}</div>
                            <RadioGroup
                                className='radio'
                                value={selectedValue}
                                onChange={e => setSelectedValue(e.target.value)}
                            >
                                <div>
                                    <FormControlLabel
                                        value='送信する'
                                        control={<Radio color="primary" />}
                                        label={<Typography>{dict.labels.送信する}</Typography>}
                                        disabled={isAssigned}
                                    />
                                    <FormControlLabel
                                        value='送信しない'
                                        control={<Radio color="primary" />}
                                        label={<Typography>{dict.labels.送信しない}</Typography>}
                                        disabled={isAssigned}
                                    />
                                </div>
                            </RadioGroup>
                    </div>
                    <div className='row'>
                        <div className='text'>{dict.labels.回答担当者}</div>
                        <div className='input'>
                            <AddressSelector
                                onChange={v => {
                                    setValidate({})
                                    setTarget(v)
                                }}
                                value={target}
                                departments={record ? record.担当部署ID.value.split(',') : [undefined]}
                                allDepartments={allDepartments}
                                setAllDepartments={setAllDepartments}
                                setErr={setErr}
                                multi={false}
                                validate={validate.target}
                            />
                        {validate.target && <div className='errMessage'>{dict.messages.未入力エラー}</div>}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='text'>{dict.labels.サブ担当者}</div>
                        <div className='input'>
                            <AddressSelector
                                onChange={v => {
                                    setSubTarget(v)
                                }}
                                value={subTarget}
                                departments={record ? record.担当部署ID.value.split(',') : [undefined]}
                                allDepartments={allDepartments}
                                setAllDepartments={setAllDepartments}
                                setErr={setErr}
                                multi={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </SendModal>
    )
}


export default AssignReplierModal
