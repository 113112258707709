import { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ChangeReplierModal from './ChangeReplierModal'
import AssignReplierModal from './AssignReplierModal'

const useStyles = makeStyles((theme) => ({
    root : {
        display : 'flex',
        marginBottom : '4px',
        alignItems : 'center',
        justifyContent: "spaceBetween",
        
        '& .block' : {
            display : 'flex',
            flexDirection: 'column',
        },
    },
    chargeName : {
        fontSize : '1.1em',
        marginRight : '4px',
        display : 'flex',
        maxWidth : '1000px',

        '& .label::after' : {
            content : '":"',
            margin : 5,
        },
    },
    buttons : {
        display : 'flex',
        flexDirection: 'column',
        marginRight : '10px',
    }
}))

const AnswerSetting = ({
    isAdmin,             // 管理者かどうかの真偽値
    isAssigner,          // 割振り担当者かどうかの真偽値
    isReplier,           // 回答担当者かどうかの真偽値
    showMsg,             // メッセージ表示関数
    appNo,               // フォームアプリID
    currentAppNo,        // 現在の転送先フォームアプリID
    record,              // 問合せ情報
    getMailtemplates,    // メールテンプレートゲッター
    formInfo,            // フォーム情報
    userInfo,            // ログインユーザ情報
    allDepartments,      // 全部署の名前ID対応リスト
    setAllDepartments,   // 全部署の名前ID対応リストセッター
    dict,                // 多言語辞書
    setErr,              // エラーセッター
    form,                // 画面構築用フォーム情報
}) => {
    const [operation, setOperation] = useState('')
    const closeModal = useCallback(() => setOperation(''), [])
    const classes = useStyles()

    if (!record) return null
    return (<>
        <div className={classes.root}>
            <div className={classes.buttons}>
                {(record.問合せステータス.value === '未対応' && (isAdmin ||isAssigner)) &&
                <Button
                    variant="contained"
                    onClick={() => {
                        setOperation('回答者設定')
                    }}
                >
                    {dict.labels.回答者設定}
                </Button>
                }
                {(record.問合せステータス.value === '受付済み' && (isAdmin ||isAssigner || isReplier)) &&
                <Button
                    variant="contained"
                    onClick={() => {
                        setOperation('回答者変更')
                    }}
                >
                    {dict.labels.回答者変更}
                </Button>
                }
            </div>
            <div className="block">
                {record.問合せステータス.value !== '未対応' &&
                <div className={classes.chargeName}>
                    <div className='label'>{dict.labels.回答担当者}</div>
                    <div>{record.担当者名.value}</div>
                </div>
                }
                {record.問合せステータス.value !== '未対応' &&
                <div className={classes.chargeName}>
                    <div className='label'>{dict.labels.サブ担当者}</div>
                    <div>{(record.サブ担当者.value || []).map(o => o.label).join(',')}</div>
                </div>
                }
            </div>
        </div>
        <ChangeReplierModal
            showMsg={showMsg}
            operation={operation}
            closeModal={closeModal}
            appNo={appNo}
            record={record}
            userInfo={userInfo}
            allDepartments={allDepartments}
            setAllDepartments={setAllDepartments}
            dict={dict}
            setErr={setErr}
            mailtemplate={getMailtemplates((currentAppNo || appNo), formInfo, record, userInfo, ['回答者通知'], form)}
         />
        <AssignReplierModal
            showMsg={showMsg}
            operation={operation}
            closeModal={closeModal}
            appNo={appNo}
            record={record}
            userInfo={userInfo}
            allDepartments={allDepartments}
            setAllDepartments={setAllDepartments}
            dict={dict}
            setErr={setErr}
            mailtemplate={getMailtemplates((currentAppNo || appNo), formInfo, record, userInfo, ['受付自動返信', '回答者通知'],form)}
        />
    </>)
}

export default AnswerSetting
