/**
 * 利用するカスタム項目名を設定する
 * 
 * カスタム項目名
 *   client
 *   email
 *   levelItems
 *   otherChoice
 *   pcAddress
 *   stAddress
 */
const customUse = {
    client : false,
    email : false,
    levelItems : true,
    otherChoice : true,
    pcAddress : true,
    stAddress : true,
}

export default customUse
