import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/core/Alert'

const useStyles = makeStyles(theme => ({
    root : {
        marginBottom : 8,
    }
}))

const types = ['success', 'info', 'warning', 'error']


const WcmsMessage = ({msg}) => {
    const classes = useStyles()

    if (!msg || !types.includes(msg.type)) return null

    return (
        <div className={classes.root}>
            <Alert severity={msg.type}>{msg.text}</Alert>
        </div>
    )
}

export default WcmsMessage

