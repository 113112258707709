import CircularProgress from '@material-ui/core/CircularProgress'

const MiniSpinner =({spinnerOpen}) => {
    return (<>
        {spinnerOpen &&
            <CircularProgress/>
        }
    </>)
}

export default MiniSpinner