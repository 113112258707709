/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:24bf692c-0cee-4411-a9ff-2db8314f276f",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_dZPJkDzYt",
    "aws_user_pools_web_client_id": "2dvn0ceq2ivb4veq1hmmlm0i1j",
    "oauth": {
        "domain": "toraywcms4de88089-4de88089-maintenanc.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://maint-wcms.dev-wcms-canon-electec.com/",
        "redirectSignOut": "https://maint-wcms.dev-wcms-canon-electec.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "toraywcmsmaintenance94105-maintenanc",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "wcmsapi",
            "endpoint": "https://mems193562.execute-api.ap-northeast-1.amazonaws.com/maintenanc",
            "region": "ap-northeast-1"
        }
    ],
    "aws_content_delivery_bucket": "toraywcms-20210629152001-hostingbucket-maintenanc",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d28rq4hcpjiq90.cloudfront.net"
};


export default awsmobile;
