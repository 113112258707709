import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import Amplify, { API, Storage } from 'aws-amplify'
import awsconfig from './aws-exports'
import WcmsModal from './WcmsModal'
import Spinner from './Spinner'
import TextField from '@material-ui/core/TextField'

Amplify.configure(awsconfig)
const apiname = awsconfig.aws_cloud_logic_custom[0].name

const solidLine = 'solid 1px #ccc'
const useStyles = makeStyles(theme => ({
    root : {
        '& .text' : {
            flexWrap : 'nowrap',
            whiteSpace : 'pre-wrap',
            wordBreak : 'break-word',
        },
        '& .title': {  
            paddingTop : 30,
            fontWeight : 'bold',
        },    
        '& .mail' : {
            flexWrap : 'wrap',
            borderTop : solidLine,
            borderLeft : solidLine,
            borderRight : solidLine,
            fontSize : '14px',

            '& .item' : {
                display : 'flex',

                '& > div' : {
                    padding : '2px 4px',
                    borderBottom : solidLine,
                },
                '& .label' : {
                    width : 160,
                    background : '#eee',
                },
                '& .value' : {
                    width : 600,
                    borderLeft : solidLine,
                    whiteSpace : 'pre-wrap',
                    wordBreak : 'break-word',
                },
            },
        },
    },
}))

 /**
  * 送信モーダルコンポーネント
  */
const SendModal = ({
    showMsg,        // メッセージ表示関数
    open,           // モーダル開閉真偽値
    closeModal,     // モーダルクローズ関数
    validateFunc,   // API実行前のヴァリデーション
    title,          // モーダルタイトル
    children,       // 子コンポーネント
    userInfo,       // ログインユーザ情報
    dict,           // 多言語辞書
    setErr,         // エラーセッター
    mailContents,   // 送信するメールコンテンツ
    sendParam,      // 登録更新APIパラメータ
    setMailContents,// メールコンテンツセッター
    inquiryFiles,   // 問合せ情報内添付ファイル情報
    historyFiles,   // 回答履歴内添付ファイル情報
    record,         // レコード情報
    successMessage, // 処理成功時メッセージ
    contentEditable,// 本文編集可能真偽値
}) => {
    const classes = useStyles()
    const history = useHistory()
    const [spinnerOpen, setSpinnerOpen] = useState(false)

    // 登録更新API
    const execUpdate = async () => {
        try {
            if (inquiryFiles && Object.keys(inquiryFiles).length > 0) {
                const inquiryFile = Object.keys(inquiryFiles).reduce((o, c) => 
                    [...o, ...inquiryFiles[c].reduce((o, r) => [...o, {s3key : r.s3key, file : r.file}], [])]
                , [])
                await Promise.all(inquiryFile.map(f => Storage.put(f.s3key, f.file)))
            }
            if (historyFiles && historyFiles.length > 0) {
                const historyFile = historyFiles.slice(-1)[0].reduce((o, r) => 
                    [...o, {s3key : r.s3key, file : r.file}], []
                )
                await Promise.all(historyFile.map(f => Storage.put(f.s3key, f.file)))
            }
            // 登録更新APIパラメータにメールパラメータの追加
            const devSendParam = {...sendParam, Mails : mailContents.filter(c => c.宛先.To.length > 0).map(c => ({ 
                Destination : {
                    ToAddresses : c.宛先.To,
                    CcAddresses : c.宛先.Cc,
                    BccAddresses : c.宛先.Bcc,
                },
                Message : {
                    Subject : c.件名,
                    Text : c.本文,
                },
                Files : historyFiles ? (historyFiles.slice(-1)[0] || []).map(c => (c.s3key)) : [],
                replyTo : c.replyTo || "",
                noReply : c.noReply,
            }))}
            if (devSendParam.Mails.length === 0) {
                delete devSendParam.Mails
            }
            const ret = await API.post(apiname, '/registerContact', {body : devSendParam})
            if (ret.resultCode === '03') {
                showMsg('error', dict.messages.排他エラー)
                return false
            }
            if (ret.resultCode !== '00') throw new Error(`問合せ登録更新API NG (${ret.resultCode})`)
            return true
        } catch (e) {
            setSpinnerOpen(false)
            setErr(e)
        }
    }

    // 送信ボタン押下時ハンドラ
    const execFunc = async () => {
        setSpinnerOpen(true)
        if (validateFunc()) {
            setSpinnerOpen(false)
            return
        }
        if (!await execUpdate()) {
            setSpinnerOpen(false)
            return
        }
        closeModal()
        setSpinnerOpen(false)
        history.push('/')
        showMsg('success', successMessage)
    }

    // ファイルサイズ判定
    const fsize = b => {
        const u = ['KB', 'MB', 'GB']
        let ret = `${b} B`
        for (let s=b/1024, i=0; s > 1; s /= 1024) {
            ret = `${Math.round(s)} ${u[i++]}`
        }
        return ret
    }

    // 本文を編集した際のメールコンテンツ更新
    const updateMailContents = (value, index) => {
        setMailContents(u => {
            console.log(u[index].本文)
            u[index].本文 = value
            return [...u]
        })
    }

    // それぞれのモーダルの共通部分を返却
    return (
        <WcmsModal
            dict={dict}
            open={open}
            closeModal={closeModal}
            title={title}
            execLabel={dict.labels.送信}
            execFunc={execFunc}
        >
            <div>
                {children}
            </div>
            <div className={classes.root}>
        {mailContents.map((c, i)  => (
            <div key={i}>
                <div className="title">{c.title}</div>
                <div className="mail">
                    <div>
                        <div className="item">
                            <div className="label">{dict.labels.宛先}</div>
                            <div className="value">
                                {c.宛先.To.length > 0 && <div>{'To : '+ c.宛先.To.join(',')}</div>}
                                {c.宛先.Cc.length > 0 && <div>{'Cc : '+ c.宛先.Cc.join(',')}</div>}
                                {c.宛先.Bcc.length > 0 && <div>{'Bcc : '+ c.宛先.Bcc.join(',')}</div>}
                            </div>
                        </div>
                        <div className="item">
                            <div className="label">{dict.labels.件名}</div>
                            <div className="value">{c.件名}</div>
                        </div>
                        <div className="item">
                            <div className="label">{dict.labels.本文}</div>
                        {contentEditable ?
                            <TextField
                                className="value"
                                multiline
                                minRows={2}
                                value={c.本文}
                                onChange={e => updateMailContents(e.target.value, i)}
                            /> :
                            <div className="value">{c.本文}</div>
                        }
                        </div>
                        {(c.添付ファイル || []).length > 0 && 
                            <div className="item">
                                <div className="label">{dict.labels.添付ファイル}</div>
                                <div className="value">
                                {c.添付ファイル.map((f, i) => (
                                    <div key={i}>
                                        {f.file &&
                                        <a
                                            href={URL.createObjectURL(f.file)}
                                            title={f.name}
                                            download={f.name}
                                        >
                                            {f.name + '  ' + fsize(f.size)}
                                        </a>
                                        }
                                    </div>
                                ))}
                                </div> 
                            </div>
                        }
                    </div>
                </div>
            </div>
        ))}
            </div>
            <Spinner spinnerOpen={spinnerOpen}/>
        </WcmsModal>
    )
}

export default SendModal
