import Amplify, { API } from 'aws-amplify'
import { useState, useEffect, useCallback } from 'react'
import { categoryStyle } from './inputStyle'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import WcmsGroup from './WcmsGroup'
import InputItem from './InputItem'
import awsconfig from './aws-exports'
import valid from './validate'
import customUse from './custom/customUse'
import Spinner from './Spinner'

const useStyles = makeStyles(theme => ({
    root : {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 252px)',
        justifyContent : 'space-between',

        '& > div' : {
            width : '250px'
        }
    }
}))

Amplify.configure(awsconfig)
const apiname = awsconfig.aws_cloud_logic_custom[0].name

const Category = ({isAdmin, isAssigner, isReplier, showMsg, appNo, record, setRevision, appInfo ,dict, setErr, isTel, fields, handleFields,
    form, changeForm, customForm, changeCustomForm, validateMsg, changeValidateMsg, levelItems, handleLevelItems}) => {

    const classes = useStyles()

    const [levelCodes, setLevelCodes] = useState([])
    const [postCode, setPostCode] = useState({})
    const [address, setAddress] = useState({})
    const [selected, setSelected] = useState({})
    const [spinnerOpen, setSpinnerOpen] = useState(false)
    const changeSelected = useCallback(
        s => setSelected(c => ({...c, ...s}))
        ,[]
    )
    const changePostCode = useCallback(
        p => setPostCode(c => ({...c, ...p}))
        ,[]
    )
    const changeAddress = useCallback(
        a => setAddress(c => ({...c, ...a}))
        ,[]
    )

    /**
     * アプリ設定情報をもとに、画面構築用に配列作成
     */
    useEffect(() => {(async () => {
        if (!appInfo) return
        try {
            setAddress({})
            const f = createFields(appInfo)
            handleFields(f)
            // formの初期値にはkintoneのデフォルト値を入れておく（文字列 || 配列）
            // カテゴリ情報では添付ファイルは使用しない
            const form = Object.keys(f).reduce((o, c) => (
                {...o, [c] : ((record || {})[c] || {}).value == null ? f[c].defaultValue : ((record || {})[c] || {}).value}
            ), {})
            changeForm(form)

            // カスタムフィールド項目用のform作成
            const customForm = Object.keys(f).reduce((o, c) => {
                const  devFieldValue = ((record || {})[c] || {}).value
                if (['stAddress'].includes(f[c].customType)) {
                    return {...o, [c] : [devFieldValue == null ? '' : devFieldValue, '']}
                }
                if (f[c].customType === 'otherChoice') {
                    return {...o, [c] : devFieldValue == null ? [f[c].defaultValue, ''] : [record[c].value, record[c + '_other'].value]}
                }
                if (f[c].customType) {
                    return {...o, [c] : devFieldValue == null ? f[c].defaultValue : devFieldValue}
                }
                return {...o}
            }, {})
            changeCustomForm(customForm)

            // 階層項目取得用のフィールド一覧の設定
            const levelCodes = Object.values(f).filter(r => r.customType === 'levelItems')
                .map(r => r.code.replace(/_level[1-3]$/, ''))
            setLevelCodes(Array.from(new Set(levelCodes)))
        }
        catch (e) {
            setErr(e)
        }
    })()}, [appInfo, record, setErr, handleFields, changeForm, changeCustomForm])

    /**
     * 登録処理
     */
    const handleChange = async () => {
        setSpinnerOpen(true)
        if (validate()) {
            const params = {
                app : appNo,
                id : record.$id.value,
                revision : record.$revision.value,
                一時保存フラグ : false,
                社内カテゴリ情報 : form,
            }

            try {
                const result = await API.post(apiname, '/registerContact', {body : params})
                if (result.resultCode === '03') {
                    showMsg('error', dict.messages.排他エラー)
                    window.scrollTo(0, 0)
                } else if (result.resultCode !== '00') {
                    throw new Error(`問合せ登録更新API NG (${result.resultCode})`)
                } else {
                    setRevision(result.records.revision)
                    showMsg('info', dict.messages.カテゴリ設定完了)
                    window.scrollTo(0, 0)
                }
                setSpinnerOpen(false)
            }
            catch (e) {
                setSpinnerOpen(false)
                setErr(e)
                window.scrollTo(0, 0)
            }
        } else {
            setSpinnerOpen(false)
            showMsg('error', dict.messages.入力エラー)
            window.scrollTo(0, 0)
        }
    }

    const validate = () => {
        Object.values(fields).forEach(f => {
            const errMsg = valid(f, form[f.code], dict.messages)
            if (errMsg) {
                validateMsg[f.code] = errMsg
            }
        })
        changeValidateMsg(validateMsg)

        return Object.values(validateMsg).every(r => !r)
    }

    if (!Object.keys(fields).length) return null

    return (
        <WcmsGroup groupTitle={dict.labels.カテゴリ}>
            <div className={classes.root}>
                {Object.keys(fields).map((f) =>
                    <InputItem
                        dict={dict}
                        style={categoryStyle}
                        customUse={customUse}
                        key={f}
                        styleType={'category'}
                        field={fields[f]}
                        form={form[f]}
                        changeField={changeForm}
                        customItem={customForm[f]}
                        changeCustomForm={changeCustomForm}
                        validateMsg={validateMsg[f]}
                        changeValidateMsg={changeValidateMsg}
                        levelCodes={levelCodes}
                        postCode={postCode}
                        changePostCode={changePostCode}
                        address={address}
                        changeAddress={changeAddress}
                        selected={selected}
                        changeSelected={changeSelected}
                        levelItems={levelItems}
                        handleLevelItems={handleLevelItems}
                        handleInquiryFiles={() => {}}
                        inquiryFiles={{}}
                        changeCustomValMsg={() => {}}
                        customValMsg={[]}
                        record={record}
                    />
                )}
            </div>
            {(!isTel && (isAdmin || isAssigner || isReplier)) &&
            <Button
                variant="contained"
                onClick={handleChange}
            >
                {dict.labels.カテゴリ設定}
            </Button>
            }
            <Spinner spinnerOpen={spinnerOpen}/>
        </WcmsGroup>
    )
}

/**
 * 入力フォームを表示するためのフィールド設定をkintoneのアプリ設定情報をもとに整形する
 * カスタムする必要のある項目はcustomTypeに該当のカスタム項目名を追加する
 * 
 * カスタム項目名
 *   client
 *   email
 *   levelItems
 *   otherChoice
 *   pcAddress
 *   stAddress
 */
 const createFields = (appsInfo) => {
    const layout = appsInfo.layout.find(r => r.code === "社内カテゴリ情報").layout
    return layout.reduce((l, r) => {
        const code = r.fields[0].code

        const f = {}
        if (layout.some(r => r.fields[0].code === code + '_other')) {
            f.customType = 'otherChoice'
        } else if (/_pc_address$/.test(code)) {
            const p = code.replace(/_pc_address$/, '')
            if (layout.some(r => r.fields[0].code === p + '_st_address')) {
                f.customType = 'pcAddress'
            }
        } else if (/_st_address$/.test(code)) {
            const p = code.replace(/_st_address$/, '')
            if (layout.some(r => r.fields[0].code === p + '_pc_address')) {
                f.customType = 'stAddress'
            }
        } else if (/_level[1-3]$/.test(code)) {
            f.customType = 'levelItems'
        } else if (code === 'client') {
            f.customType = 'client'
        } else if (code === 'emailAddress') {
            f.customType = 'email'
        }

        // 複数フィールドが関連する項目の子要素として表示するため表示しなくなるフィールドかどうか
        if (/_other$/.test(code) && layout.some(r => code.replace(/_other$/, '') ===  r.fields[0].code)) {
            f.customNoDisp = true
        }
        return {...l, [code] : {...appsInfo.properties[code], ...f}}
    }, {})
}

export default Category;