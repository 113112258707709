import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
    root : {
        '& .backdrop': {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    },
}))

const Spinner =({spinnerOpen}) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Backdrop className='backdrop' open={spinnerOpen}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default Spinner