/**
 * 拡張子の拒否リスト
 */
const attachedFileRejectExtension = {
    ade : true,
    adp : true,
    app : true,
    asp : true,
    bas : true,
    bat : true,
    cer : true,
    chm : true,
    cmd : true,
    com : true,
    cpl : true,
    crt : true,
    csh : true,
    der : true,
    exe : true,
    fxp : true,
    gadget : true,
    hlp : true,
    hta : true,
    inf : true,
    ins : true,
    isp : true,
    its : true,
    js : true,
    jse : true,
    ksh : true,
    lib : true,
    lnk : true,
    mad : true,
    maf : true,
    mag : true,
    mam : true,
    maq : true,
    mar : true,
    mas : true,
    mat : true,
    mau : true,
    mav : true,
    maw : true,
    mda : true,
    mdb : true,
    mde : true,
    mdt : true,
    mdw : true,
    mdz : true,
    msc : true,
    msh : true,
    msh1 : true,
    msh2 : true,
    mshxml : true,
    msh1xml : true,
    msh2xml : true,
    msi : true,
    msp : true,
    mst : true,
    ops : true,
    pcd : true,
    pif : true,
    plg : true,
    prf : true,
    prg : true,
    reg : true,
    scf : true,
    scr : true,
    sct : true,
    shb : true,
    shs : true,
    sys : true,
    ps1 : true,
    ps1xml : true,
    ps2 : true,
    ps2xml : true,
    psc1 : true,
    psc2 : true,
    tmp : true,
    url : true,
    vb : true,
    vbe : true,
    vbs : true,
    vps : true,
    vsmacros : true,
    vss : true,
    vst : true,
    vsw : true,
    vxd : true,
    ws : true,
    wsc : true,
    wsf : true,
    wsh : true,
    xnk : true,
}

export default attachedFileRejectExtension
