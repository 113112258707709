import { makeStyles } from '@material-ui/core/styles'
import WcmsGroup from './WcmsGroup'

const solidLine = 'solid 1px #ccc'
const useStyles = makeStyles((theme) => ({
    root : {
        display : 'flex',
        '& .text' : {
            width : '100%',
            minHeight : '100px',
            padding: '0 15px',
            whiteSpace : 'pre-wrap',
            border: solidLine
        },
    }
}))

const InvalidReason = ({
    record,              // 問合せ情報
    dict,                // 多言語辞書
}) => {
    const classes = useStyles()

    return (<>
        <WcmsGroup groupTitle={dict.labels.無効化理由}>
            <div className={classes.root}>
                <div className="text">
                    {record.無効化理由.value}
                </div>
            </div>
        </WcmsGroup>
    </>)
}

export default InvalidReason;