import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/core/Autocomplete'

const useStyles = makeStyles((theme) => ({
    root : {
        '& .MuiAutocomplete-inputRoot' : {
            padding : '2px 50px 0 0 !important',
            fontSize : '0.9rem !important',
        },    
    },
    paper : {
        width : 500,
        fontSize : 14,
    },
    option : {
        paddingTop : 2,
        paddingBottom : 2,
    },
}))

const FormSelector = ({value, options, changeValue}) => {
    const [inputValue, setInputValue] = useState('')
    const classes = useStyles()

    return (
        <Autocomplete
            classes={{
                paper : classes.paper,
                option : classes.option,
            }}
            className={classes.root}
            options={options}
            value={value}
            inputValue={inputValue}
            getOptionLabel={o => `${o.code}：${o.name}`}
            renderInput={p => <TextField {...p} />}
            onChange={(e, v, r) => changeValue(v)}
            onInputChange={(e, v) => setInputValue(v)}
            size="small"
        />
    )
}


export default FormSelector;
