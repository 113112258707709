import { format } from 'date-fns'

// メール置換対象項目のフィールドID
const replaceTargets = [
    'client',
    'emailAddress',
    'companyName_search',
    'yourDivision',
    'yourPosition',
    '住所_pc_address',
    'phone',
    'countries',
    '住所_st_address',
    'product_search',
    'applications',
    'postalCode',
    'address',
    'commentsQuestions'
]

// メール本文置換処理
const mailContentsReplace = (content, appNo, userInfo, formInfo, record, form) => {
    const devContent = replaceTargets.reduce((a, c) => 
        a.replace(new RegExp(`{${c}}`, 'g'), (((record || {})[c] || {}).value || ''))
    , content)
    return devContent
        .replace(/{AD_companyName}/g, ((userInfo || {})['custom:company'] || ''))
        .replace(/{AD_department}/g, ((userInfo || {})['custom:department'] || ''))
        .replace(/{AD_displayName}/g, ((userInfo || {}).name || ''))
        .replace(/{AD_emailAddress}/g, ((userInfo || {}).email || ''))
        .replace(
            /{replyContents}/g,
            ((((((record || {}).回答履歴テーブル || {}).value || []).slice().reverse()[1] || {}).value || {}).回答内容 || {}).value || ''
        )
        .replace(/{receiptNo}/g, (((record || {}).受付No || {}).value || ''))
        .replace(/{formTitle}/g, ((((formInfo || {})[appNo] || {}).formNames || []).find(e => e.lang === formInfo[appNo].lang).formName || ''))
        .replace(
            /{allInquiryInfo}/g,
            ((form.filter(a => a.label).map(o => (`${o.label} : ${o.value || ""}`))).join("\n")) || ''
        )
        .replace(/{wcmsDetailURL}/g, `https://${document.domain}/detail/${appNo}?r=${record.$id.value}`)
}

// メール件名置換処理
const mailTitleReplace = (content, appNo, formInfo, record) => { 
    return content
        .replace(/{receiptNo}/g, (((record || {}).受付No || {}).value || ''))
        .replace(/{formTitle}/g, ((((formInfo || {})[appNo] || {}).formNames || []).find(e => e.lang === formInfo[appNo].lang).formName || ''))
}

// 日時フォーマット
const dateFormat = t => t ? format(new Date(t), 'yyyy/MM/dd HH:mm:ss') : ''

const commonFunc = {
    mailContentsReplace : mailContentsReplace,
    mailTitleReplace : mailTitleReplace,
    dateFormat : dateFormat
}

export default commonFunc