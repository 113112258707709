import { useState, useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import Amplify, { Auth, API } from 'aws-amplify'
import WcmsAppBar from './WcmsAppBar'
import WcmsTheme from './WcmsTheme'
import List from './List'
import Detail from './Detail'
import Library from './Library'
import Err from './Err'
import awsconfig from './aws-exports'
import commonFunc from './commonFunc'
import WcmsSnackbarMessage from './WcmsSnackbarMessage'

Amplify.configure(awsconfig)
const apiname = awsconfig.aws_cloud_logic_custom[0].name

const App = () => {
    const [user, setUser] = useState(undefined)
    const [formApps, setFormApps] = useState(undefined)
    const [formInfo, setFormInfo] = useState(undefined)
    const [dict, setDict] = useState(null)
    const [isAdmin, setIsAdmin] = useState(undefined)
    const [err, setErr] = useState(null)
    const [conditions, setConditions] = useState(undefined)
    const [condFormName, setCondFormName] = useState(null)
    const [condUser, setCondUser] = useState([])
    const [condOperationUser, setCondOperationUser] = useState([])
    const [condForwardingMailAddress, setCondForwardingMailAddress] = useState([])
    const [allMailTemplates, setAllMailTemplates] = useState(undefined)
    const [allDepartments, setAllDepartments] = useState(undefined)
    const [appInfoList, setAppInfoList] = useState({})
    const [msg, setMsg] = useState(null)
    const [noticeInfo, setNoticeInfo] = useState([])

    const showMsg = (t, m) => setMsg({type : t, text : m})
    const closeMsg = () => setMsg(null)

    /** 検索条件のパラメーター値を更新する処理
     * @param {Object} c 変更する検索条件の値
     *  param形式:{ 項目名 : value値 }
     * @param {object} e エラー情報
     *  param形式:{ 項目名 : bool値}
     * @returns 検索条件を返す
     *  param形式:{ 項目名 : value値,
     *              err : { 項目名 : bool値}
     *  }
     * 
     *  conditionsの各検索条件の値を更新し、
     *  errキーに対して各検索条件のエラー有無を格納する
     */
    const changeCondition = (c, e) => setConditions(v => {
        // 検索条件を更新
        const tmpCond = {...v, ...c}
        // エラー情報を追加
        return {...tmpCond, err : {...(v.err || {}), ...e}}
    })

    // 検索条件：フォーム名変更
    const changeFormName = v => {
        setCondFormName(v)
        changeCondition({フォーム名 : (v || {}).name || ""})
    }

    // 検索条件：担当者
    const changeCondUser = v => {
        setCondUser(v)
        changeCondition({担当者メールアドレス : (v[0] || {}).value || ""})
    }
    // 検索条件：操作者
    const changeCondOperationUser = v => {
        setCondOperationUser(v)
        const errInfo = {操作者メールアドレス : (v[0] || {}).err}
        changeCondition({操作者メールアドレス : (v[0] || {}).value || ""}, errInfo)
    }
    // 検索条件：転送先メールアドレス
    const changeCondForwardingMailAddress = v => {
        setCondForwardingMailAddress(v)
        const errInfo = {転送先メールアドレス : (v[0] || {}).err}
        changeCondition({転送先メールアドレス : (v[0] || {}).value || ""}, errInfo)
    }

    // 認証
    useEffect(() => {(async () => {
        if (user) return
        const _user = await Auth.currentAuthenticatedUser().catch(e => null)
        if (_user) {
            setUser(_user)
        }
        else {
            Auth.federatedSignIn()
        }
    })()}, [user])

    // システム管理マスタ取得
    useEffect(() => {(async () => {
        if (!user) return
        try {
            const {record : sys, resultCode} = await API.post(apiname, '/getSystemManage')
            if (resultCode !== '00') throw new Error(`システム管理マスタ取得API NG (${resultCode})`)

            // ログインユーザがシステム管理であるか判定
            const _isAdmin = sys.管理者.some(a => a.value.メールアドレス.value === user.attributes.email)
            setIsAdmin(_isAdmin)

            // ブラウザ設定言語に合わせて辞書を加工
            setDict(convertDictionary(sys.多言語辞書))
        }
        catch (e) {
            setErr(e)
        }
    })()}, [user])


    // 一覧の検索条件、電話応対記録、転送先指定で使用するフォーム情報リストを作成する
    // 転送を考慮して全てのフォームが対象
    useEffect(() => {(async () => {
        if (!user || formInfo) return
        // 問合せ情報の取得
        const {record : recs, resultCode} = await API.post(apiname, '/getContactManage')
        if (resultCode !== '00') throw new Error(`問合せ情報取得API NG (${resultCode})`)
        console.log(recs)

        // フォーム情報に加工して保存
        setFormInfo(recs.reduce((a, r) => ({...a, [r.問合せフォームアプリID] : {
            lang : r.言語,
            code : r.問合せフォームアプリコード,
            companyId : r.会社ID,
            noticeBoss : r.上司への通知,
            excludeTransfer : r.転送先除外.length !== 0 ? true : false,
            formNames : r.フォーム名.map(f => ({
                lang : f.value.言語_フォーム名.value,
                formName : f.value.フォーム名.value,
            })),
            assigners : r.割振り担当.map(f => ({
                assigner : f.value.割振り担当.value,
                sendCategory : f.value.送信区分.value,
                rep : f.value.代表.value.length > 0 ? true : false,
            })),
            oftenUsers : r.よく使う転送先.filter(f => f.value.メールアドレス.value).map(f => ({
                label : `${f.value.部署.value}/${f.value.名前.value}/${f.value.Note.value}`,
                default : true,
                value : f.value.メールアドレス.value,
            })),
            companyNames : r.言語依存情報.map(f => ({
                lang : f.value.言語.value,
                companyName : f.value.会社名_転送先選択用.value,
            })),
            departments : r.担当部署,
            companySortNum : r.会社ソート番号,
            formSortNum : r.フォームソート番号,
        }}), {}))
    })()}, [user, formInfo])

    // 担当する問合せアプリ情報取得
    useEffect(() => {(async () => {
        if (!user || isAdmin === undefined) return
        try {
            // 担当問合せアプリIDの取得
            const {app : apps, resultCode} = await API.post(apiname, '/getChargeAppID', {
                headers : {
                    'x-amz-user-agent' : user.signInUserSession.idToken.jwtToken,
                },
                body : {
                    isAdmin : isAdmin,
                },
            })
            if (resultCode !== '00') throw new Error(`担当問合せアプリID取得API NG (${resultCode})`)
            setFormApps(apps)

            // 検索条件の初期設定
            const u = user.attributes
            setConditions({
                問合せステータス : isAdmin ? ['未対応'] : ['未対応', '受付済み'],
                受付No : '',
                言語 : '',
                部署ID : apps.部署ID,
                clientCompany : '',
                clientName : '',
                product : '',
                担当者メールアドレス : isAdmin ? '' : u.email,
                サイト : '',
                登録日時from : '',
                登録日時to : '',
                受付日時from : '',
                受付日時to : '',
                更新日時from : '',
                更新日時to : '',
                フォーム名 : '',
                操作者メールアドレス : '',
                キーワード検索 : '',
                転送先メールアドレス : '',
            })
            if (!isAdmin) setCondUser([{label : u.name, value : u.email}])
        }
        catch (e) {
            setErr(e)
        }
    })()}, [user, isAdmin])

    // メールテンプレートの取得
    useEffect(() => {(async () => {
        if(!user || allMailTemplates) return
        try {
            const ret = await API.post(apiname, '/getMailTemplate')
            if (ret.resultCode !== '00') throw new Error(`メールテンプレート取得API NG (${ret.resultCode})`)
            setAllMailTemplates(ret.mailTemplateInfo)
        }
        catch (e) {
            setErr(e)
        }
    })()}, [user, allMailTemplates])

    // お知らせの取得
    useEffect(() => {(async () => {
        if (!dict) return
        try {
            const ret = await API.post(apiname, '/getNoticeInfo')
            if (ret.resultCode !== '00') throw new Error(`お知らせ取得API NG (${ret.resultCode})`)
            setNoticeInfo(ret.records.slice(0, 1))
        } catch (e) {
            setMsg({type : 'error', text : dict.messages.お知らせ取得失敗})
            console.log(e)
        }
    })()}, [dict])

    // メールテンプレートゲッター
    // mailDivは該当のメール区分をString配列で持つ
    const getMailtemplates = (appNo, formInfo, record, userInfo, mailDiv, form) => {
        // すべてのデータが揃っていなければ空オブジェクトを返す
        if (!allMailTemplates || !appNo || !formInfo || !record || !userInfo || !form.length) return {}
        return mailDiv.reduce((a, c) => 
            ({...a, [c] : ((
                allMailTemplates[appNo] &&
                allMailTemplates[appNo][formInfo[appNo].lang] &&
                allMailTemplates[appNo][formInfo[appNo].lang][c]) ?
                allMailTemplates[appNo][formInfo[appNo].lang][c] :
                allMailTemplates.共通[formInfo[appNo].lang][c])
                .map(c => ({
                    テンプレートタイトル : c.テンプレートタイトル,
                    件名 : commonFunc.mailTitleReplace(c.件名, appNo, formInfo, record),
                    本文 : commonFunc.mailContentsReplace(c.本文, appNo, userInfo, formInfo, record, form),
                    オプション設定 : c.オプション設定
                }))
        }),{})
    }

    // エラー切り分け用内部コンポーネント
    const ThrowError = () => {
        useEffect(() => {
            const e = new Error('不正なURL')
            e.reason = 'incorrectUrl'
            setErr(e)
        },[])
        return null
    }

    // エラー画面
    if (err) return (<Err err={err} />)

    // 各データ取得が終わっていない場合はnullを返す
    if (!user || !formApps || !dict || !allMailTemplates || !formInfo) return null
    return (
        <BrowserRouter>
            <ThemeProvider theme={WcmsTheme}>
                <WcmsAppBar dict={dict}/>
                <div>
                    <Switch>
                        <Route exact path={['/', '/list']}>
                            <List
                                isAdmin={isAdmin}
                                userInfo={user.attributes}
                                formApps={formApps}
                                formInfo={formInfo}
                                conditions={conditions}
                                changeCondition={changeCondition}
                                condFormName={condFormName}
                                changeFormName={changeFormName}
                                condUser={condUser}
                                changeCondUser={changeCondUser}
                                condOperationUser={condOperationUser}
                                changeCondOperationUser={changeCondOperationUser}
                                condForwardingMailAddress={condForwardingMailAddress}
                                changeCondForwardingMailAddress={changeCondForwardingMailAddress}
                                noticeInfo={noticeInfo}
                                dict={dict}
                                setErr={setErr}
                            />
                        </Route>
                        <Route exact path='/detail/:appNo'>
                            <Detail
                                isAdmin={isAdmin}
                                formInfo={formInfo}
                                userInfo={user.attributes}
                                formApps={formApps}
                                getMailtemplates={getMailtemplates}
                                appInfoList={appInfoList}
                                setAppInfoList={setAppInfoList}
                                allDepartments={allDepartments}
                                setAllDepartments={setAllDepartments}
                                dict={dict}
                                setErr={setErr}
                                showMsg={showMsg}
                            />
                        </Route>
                        <Route exact path={['/library']}>
                            <Library
                                dict={dict}
                                setErr={setErr}
                                showMsg={showMsg}
                            />
                        </Route>
                        <Route>
                            <ThrowError/>
                        </Route>
                    </Switch>
                </div>
                <WcmsSnackbarMessage
                    msg={msg}
                    closeMsg={closeMsg}
                />
            </ThemeProvider>
        </BrowserRouter>
    )
}

// 辞書変換
const convertDictionary = d => {
    // 表示言語の決定
    let lang = (navigator.language || '').split('-')[0]
    lang = d.languages.includes(lang) ? lang : 'en'

    // 画面タイトル／ラベル／メッセージ辞書の変換
    const dict = ['titles', 'labels', 'messages'].reduce((o, k) =>
        ({...o, [k] : Object.keys(d[k]).reduce((o, l) => ({...o, [l] : d[k][l][lang].replace(/\\n/g, '\n')}), {})}), {})

    // 選択肢辞書の変換
    dict.values = Object.keys(d.values).reduce((o, v) =>
        ({...o, [v] : d.values[v].map(l => ({value : l.value, name : l.name[lang]}))}), {})

    dict.languages = d.languages
    dict.lang = lang
    return dict
}

export default App;
