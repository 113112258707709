import { makeStyles } from '@material-ui/core/styles'
import WcmsGroup from './WcmsGroup'

const solidLine = 'solid 1px #ccc'
const useStyles = makeStyles((theme) => ({
    root : {
        borderCollapse: 'collapse',
        width : 1040,

        '& th, td' : {
            border : solidLine,
            padding : '4px 4px',
        },
        '& th' : {
            fontWeight : 'normal',
            backgroundColor : '#eee',
        },
        '& th.operator' : {
            width : 200,
        },
        '& th.operation' : {
            width : 150,
        },
        '& th.body' : {
            width : 560,
        },
        '& th.time' : {
            width : 130,
        },
        '& td' : {
            textAlign : 'left'
        },
    }
}))

const OperationHistory = ({
    record,              // 問合せ情報
    dict,                // 多言語辞書
}) => {
    const classes = useStyles()

    return (<>
        <WcmsGroup groupTitle={dict.labels.操作履歴}>
            <table className={classes.root}>
                <thead>
                    <tr>
                        <th className='operation'>{dict.labels.操作区分}</th>
                        <th className='operator'>{dict.labels.操作者}</th>
                        <th className='operator emailAddress'>{dict.labels.操作者メールアドレス}</th>
                        <th className='body'>{dict.labels.補足}</th>
                        <th className='time'>{dict.labels.操作日時}</th>
                    </tr>
                </thead>
            {record && 
                <tbody>
                {record.操作履歴テーブル.value.map((c, i) => (
                    <tr key={i}>
                        <td>
                            <div>{(dict.values.操作区分.find(d => d.value === ((c.value || {}).操作区分 || {}).value) || {}).name || ''}</div>
                        </td>
                        <td>
                            <div>{((c.value || {}).操作者 || {}).value || ''}</div>
                        </td>
                        <td>
                            <div>{((c.value || {}).操作者メールアドレス || {}).value || ''}</div>
                        </td>
                        <td>
                            <div>{((c.value || {}).補足 || {}).value || ''}</div>
                        </td>   
                        <td>
                            <div>
                                {((c.value || {}).操作日時 || {}).value ? new Date(c.value.操作日時.value).toLocaleString() : ''}
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            }
            </table>
        </WcmsGroup>
    </>)
}

export default OperationHistory;